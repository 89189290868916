import { Helmet } from 'react-helmet';
import { 
  Box, Container, Grid,
  Button, Card, CardContent, TextField, InputAdornment, SvgIcon, LinearProgress, Slide, Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import PackageCard from 'src/components/packages/PackageCard';
import AppLayout from 'src/components/AppLayout';
import React, { useState, useEffect, useRef } from 'react'
import { Search as SearchIcon } from 'react-feather';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchPackages, fetchUtilities } from '../redux/actions/general'
import { useHistory } from 'react-router'; 
import { useMediaQuery } from 'react-responsive'


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const Packages = ({ fetchPackages, fetchUtilities, general, user }) => {
    const [ userData, setUserData ] = useState(user.userData)
	const history = useHistory()
    const isPhone = useMediaQuery({ query: '(max-width: 812px)' })    

	const editPack = (pack, newPack = false) => {
		history.push('packages/edit', { pack, newPack })
	}

	const [fetching, setFetching] = useState(false);
    
	const [deleting, setDeleting] = useState(false);
	
	const [packages, setPackages] = useState([]);
	const [searchResult, setSearchResult] = useState([]);
	const [searchString, setSearchString] = useState('');

	const init = async () => {
		setFetching(true);
		try{
            await fetchPackages(userData?.uid);
            await fetchUtilities();
		}
		catch(err) {
		    console.log(err)
		}
		finally {
		    setFetching(false)
		}
	}

	useEffect( () => {
		init()
	}, [])

    useEffect(() => {
        setUserData(user.userData)
    }, [user])

	useEffect( () => {
		searchString ? search(searchString) : setSearchResult([])
	}, [searchString])

	useEffect(() => {
		setPackages(general.packages)
	}, [general])


	const addPackage = () => {
        const pack = {
            type: '',
            semester: '',
            department: '',
            level: '',
            price: '',
            university: '',
            description: '',
            courses: [{
                title: '',
                materials: [],
                features: [],
                audios: [],
                references: []
            }],
            subscribers: []
        }
		editPack(pack, true)
	}

	const search = (string) => {
        const str = string.toLowerCase()
		setSearchResult([
            ...packages.filter((pack) => (
                pack.level.toLowerCase().includes(str) ||
                pack.department.toLowerCase().includes(str) ||
                pack.type.toLowerCase().includes(str) ||
                pack.semester.toLowerCase().includes(str) ||
                pack.university.toLowerCase().includes(str) ||
                pack.description.toLowerCase().includes(str)
            ))
        ])
	}


	return (
		<AppLayout>
			<Helmet>
				<title>Packages | GMT Admin</title>
			</Helmet>

			<Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
				<Container maxWidth={false}>
                    { fetching && <LinearProgress style={{ marginBottom: 20 }} /> }

                    <Box>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button color="primary" variant="contained" onClick={() => addPackage()}>
                            Add Package
                        </Button>
                        <Button color="primary" variant="text" onClick={() => init()}>
                            Refresh
                        </Button>
                        </Box>

                        <Box sx={{ mt: 3 }}>
                        <Card>
                            <CardContent style={{ padding: '0 20px'}}>
                                <Grid container style={{ alignItems: 'center' }}>
                                    <Grid item xs={12} md={6}>
                                        <Typography color="textPrimary" style={{ fontWeight: '900', fontSize: 18, margin: isPhone ? '15px 0 0' : '15px 0' }}>
                                            All Packages: {packages.length}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Box sx={{ margin: '15px 0' }}>
                                            <TextField fullWidth 
                                                value={searchString}
                                                onChange={(e) => setSearchString(e.target.value)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SvgIcon fontSize="small" color="action">
                                                                <SearchIcon />
                                                            </SvgIcon>
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment: (
                                                        searchString && 
                                                        <InputAdornment onClick={() => { setSearchString('') }} position="start" style={{ cursor: 'pointer' }}>
                                                            <SvgIcon fontSize="small" color="action">
                                                                <Close />
                                                            </SvgIcon>
                                                        </InputAdornment>
                                                    )
                                                }} 
                                                placeholder="Search packages" variant="outlined"
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        </Box>
                    </Box>

                    <Box sx={{ pt: 3 }}>
                        {
                            (searchString) &&
                            <Typography align="center" color="textPrimary" variant="h5" style={{ marginBottom: '1rem' }}>
                                {searchResult.length} Search Result(s)
                            </Typography>
                        }
                        <Grid container spacing={3}>
                            {
                                (searchString) ?
                                searchResult.sort((a, b) => (a.level < b.level) ? 1 : -1).sort((a, b) => (a.department > b.department) ? 1 : -1).map((pack, key) => (
                                    <Grid item key={key} lg={4} md={4} xs={12} sm={6}>
                                        <PackageCard pack={pack} edit={editPack} />
                                    </Grid>
                                ))   
                                :
                                packages.sort((a, b) => (a.level < b.level) ? 1 : -1).sort((a, b) => (a.department > b.department) ? 1 : -1).map((pack, key) => (
                                    <Grid item key={key} lg={4} md={4} xs={12} sm={6}>
                                        <PackageCard pack={pack} edit={editPack} />
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Box>
				
				    <Box sx={{ display: 'flex', justifyContent: 'center', pt: 3 }}></Box>
				</Container>
			</Box>

			{/* <Dialog TransitionComponent={Transition} maxWidth='lg' open={deleteModal} scroll='paper' disableBackdropClick disableEscapeKeyDown aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
				<DialogTitle id="scroll-dialog-title">Confirm Delete
				</DialogTitle>
				<DialogContent dividers={true}>
					<Typography>Are you sure you want to delete this package?</Typography>
				</DialogContent>
				<DialogActions>
				<Button disabled={deleting} onClick={() => setDeleteModal(false)} color="primary">
					Cancel
				</Button>
				<Button disabled={deleting}  variant="contained" onClick={() => deletePackage() } style={{  backgroundColor: 'red' }}>
					{ deleting ? 'Deleting...' : 'Confirm'}
				</Button>
				</DialogActions>
			</Dialog> */}
			

		</AppLayout>
	)
}
const mapStateToProps = ({ general, user }) => ({ general, user });

const mapDispatchToProps = (dispatch) =>{
  return bindActionCreators({ fetchPackages, fetchUtilities }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Packages);
