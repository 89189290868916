import PropTypes from 'prop-types';
import { Box, Button, Card, CardContent, Divider, Grid, Typography } from '@material-ui/core';
import { GetApp, Book } from '@material-ui/icons';

const PackageCard = ({ pack, edit, ...rest }) => {
  const courses = pack.courses ? pack.courses : []
  let materials = 0
  let audios = 0
  courses.map((course) => { materials += course.materials.length; audios += course?.audios?.length || 0;  })
  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }} {...rest}>
      <CardContent style={{ marginBottom: 0 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', pt: 2, pb: 2}}>
          <Book style={{ fontSize: 40 }} />
        </Box>

        <Typography align="center" color="textPrimary" gutterBottom variant="h5" style={{  marginBottom: 5 }}>
          {pack.department}
        </Typography>

        <Typography align="center" color="textPrimary" gutterBottom variant="h5">
          {pack.level} {pack.semester}
        </Typography>
        <Typography align="center" color="textPrimary" gutterBottom variant="h6" style={{ textTransform: 'capitalize' }}>
          {pack.type}
        </Typography>

        <Typography align="center" color="textPrimary" style={{ fontSize: 14, marginBottom: 5 }}>
          {pack.university}
        </Typography>

        <Typography align="center" color="textPrimary" style={{ fontSize: 14, marginBottom: 5 }}>
          <b>&#8358;{pack.price}</b>
        </Typography>

        <Grid container className="package-card-info">
            <Grid item xs={4}>
                <Typography>
                    Courses: <br /> {courses.length}
                </Typography>
            </Grid>
            
            <Grid item xs={4}>
                <Typography>
                    PDFs: <br/> {materials}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography>
                    Audios: <br /> {audios}
                </Typography>
            </Grid>
        </Grid>

        <Divider />

        <Grid container className="package-card-info footer">
            <Grid item xs={4}>
                <Typography>
                    <small>Updated</small>
                    { pack.updated ? new Date(pack.updated).toDateString() : new Date(pack.created).toDateString() }
                </Typography>
            </Grid>

            <Grid item xs={4}>
                <Typography>
                    <small>Subscribers</small>
                    <span>{pack.subscribers ? pack.subscribers.length : 0 }</span>
                </Typography>
            </Grid>

            <Grid item xs={4} onClick={() => edit(pack)} className="button">
                <Typography>
                    <small>Action</small>
                    <span>Edit</span>
                </Typography>
            </Grid>

        </Grid>
      </CardContent>
    </Card>
  );
}
export default PackageCard;
