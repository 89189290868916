import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Button, Card, CardHeader, Chip, Divider, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Tooltip, Dialog, DialogContent, Grid, Typography, Avatar } from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { Call, Cancel, Email } from '@material-ui/icons';

const ContributorsList = ({ contributors, limit = contributors.length, ...props}) => {
    const history = useHistory()
    const [openContributor, setOpen]= useState(null)
    return (
        <Card {...props}>
            <CardHeader title={limit === contributors.length ? 'All Contributors' : `New Contributors (${limit ? limit : 'All'})`} />
            <Divider />

            <PerfectScrollbar>
                <Box sx={{ minWidth: 800 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>S/N</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Phone</TableCell>
                                <TableCell>Registered</TableCell>
                                <TableCell>Action</TableCell>

                                </TableRow>
                        </TableHead>

                        <TableBody>
                            {
                                contributors.sort((a, b) => (a.registered > b.registered) ? -1 : 1).map((contributor, key) => (
                                (key < limit) &&
                                    <TableRow
                                        hover
                                        key={key}
                                    >
                                        <TableCell>
                                            {key + 1}
                                        </TableCell>
                                        <TableCell>
                                            {contributor.name}
                                        </TableCell>
                                        <TableCell>
                                            {contributor.email}
                                        </TableCell>
                                        <TableCell>
                                            {contributor.phone}
                                        </TableCell>
                                        {/* <TableCell>
                                            {contributor.subAccount}
                                        </TableCell> */}
                                        <TableCell>
                                            {moment(contributor.registered).format('DD MMM, YYYY')}
                                        </TableCell>
                                        <TableCell>
                                            <Button onClick={()=>setOpen(contributor)}>View More</Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </Box>
            </PerfectScrollbar>


            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    p: 2
                }}
            >
                { limit < contributors.length && 
                    <Button
                        color="primary"
                        endIcon={<ArrowRightIcon />}
                        size="small"
                        variant="text"
                        onClick={() => history.push('/contributors-list')}
                    >
                    View all
                    </Button>
                }
            </Box>
        
            <Dialog open={Boolean(openContributor)}>
                <DialogContent>
                    <Grid container className="contributor-view">
                        <Grid item xs={12} container justifyContent="center">
                            <Avatar src={openContributor?.photoURL} style={{ height: 150, width: 150 }} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography>Name: <span>{openContributor?.name}</span></Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography>Email: <span>{openContributor?.email}</span></Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography>Phone: <span>{openContributor?.phone}</span></Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography>Qualification: <span>{openContributor?.qualification}</span></Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography>University: <span>{openContributor?.university}</span></Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography>Department: <span>{openContributor?.department}</span></Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography>Sub Account: <span>{openContributor?.subAccount}</span></Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography>Registered: <span>{moment(openContributor?.registered).format('DD MMM, YYYY')}</span></Typography>
                        </Grid>
                        <Grid item xs={12} container justifyContent="space-between" style={{ marginTop:'30px' }}>
                            <Button startIcon={<Call />} variant="contained" onClick={()=>window.open(`tel:${openContributor?.phone}`)}>Call</Button>
                            <Button startIcon={<Email />} variant="contained" onClick={()=> window.open(`mailto:${openContributor?.email}`)}>Email</Button>
                            <Button startIcon={<Cancel />} variant="contained" onClick={()=>setOpen(null)}>Close</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            
        </Card>
    );
}
export default ContributorsList;
