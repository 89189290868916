import React, { useState, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Card, Divider, Table, Button, TableBody, TableCell, TablePagination, TableRow, Typography, List, Icon } from '@material-ui/core';
import { Delete, Flag } from '@material-ui/icons';
import firebase from "../../redux/api/config";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchUtilities } from '../../redux/actions/general'

const UserList = ({ list, general, fetchUtilities, ...rest }) => {
  const [users, setUsers] = useState(list)
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds, id);
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(1));
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  useEffect(() => {
    setUsers(general.utilities.users)
  }, [general.utilities.users])


  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const findDuplicate = (user) => {
    const id = users.filter((usr) => usr.email === user.email );
    return id.length > 1 ? true : false;
  }

  const deleteDuplicate = async (uid) => {
    await firebase.firestore().collection('Users').doc(uid).delete()
    .then(async () => {
      await fetchUtilities();
      alert("success")
    })
    .catch((err) => {
      console.log(err)
    })
  }

  const copy = (id) => {
    var node = document.getElementById(id);
    if (document.body.createTextRange) {
      const range = document.body.createTextRange();
      range.moveToElementText(node);
      range.select();
      document.execCommand("copy");
    } else if (window.getSelection) {
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(node);
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand("copy");
    } else {
        console.warn("Could not select text in node: Unsupported browser.");
    }
  }

  return (
    <Card>
      <PerfectScrollbar>
        <Box>
          
          <Box style={{ display: 'flex', alignItems: 'center', padding: 20, justifyContent: 'space-between' }}>
            <Typography variant="h3">Users {users?.length}</Typography>
          </Box>
          <Divider />

          <Table>
            <TableBody>
              {users.sort((a, b) => (a.email > b.email) ? 1 : -1).map((usr, key) => (
                <TableRow hover key={key}>
                  <TableCell style={{ width: '5px' }}>
                    <Typography color="textPrimary" variant="body1" style={{ textAlign: 'center' }}>
                      { findDuplicate(usr) ? <Flag style={{ color: 'red' }} /> : key + 1}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textPrimary" variant="body1" id={'copy'+key} onClick={() => copy('copy'+key)}>
                      {usr.email}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textPrimary" variant="body1">
                      {usr.uid}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    { findDuplicate(usr) && <Delete style={{ color: 'red', cursor: 'pointer' }} onClick={() => deleteDuplicate(usr.uid)} />}
                    {/* <Delete style={{ cursor: 'pointer' }} onClick={() => deleteDuplicate(usr.uid)} /> */}
                    
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      {/* <TablePagination
        component="div"
        count={list.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        // rowsPerPageOptions={[5, 10, 25]}
      /> */}
    </Card>
  );
};

const mapStateToProps = ({ general }) => ({ general })

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ fetchUtilities }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UserList);

