import { Helmet } from 'react-helmet';
import { Box, Container, Button, Grid, LinearProgress } from '@material-ui/core';
import ContributorsList from 'src/components/contributors/ContributorsList';
import AppLayout from 'src/components/AppLayout';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchAll } from '../redux/actions/general'
import React, { useEffect, useState } from 'react'

const Contributors = ({ fetchAll, general, user }) => {
    const [ utilities, setAnalytics ] = useState(general)
    const [ fetching, setFetching ] = useState(false)
    const [ userData, setUserData ] = useState(user.userData)

    const fetchAllData = async () => {
        setFetching(true)
        try{
            await fetchAll(userData?.uid);
            
        }
        catch( err) {
    
        }
        finally{
            setFetching(false)
        }
    }
  
        
    useEffect(() => {
        fetchAllData()
    }, [])
    
    useEffect(() => {
        setAnalytics(general)
    }, [general])
    
    useEffect(() => {
        setUserData(user.userData)
    }, [user])

    return (
        <AppLayout>
            <Helmet>
                <title>Contributors | GMT Dashboard</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 3
                }}
            >
            <Container maxWidth={false}>
                { fetching && <LinearProgress style={{ marginBottom: 20}} /> }
                <Box sx={{ pt: 3 }}>
                    <ContributorsList contributors={utilities.contributors} />
                </Box>
            </Container>
            </Box>
        </AppLayout>
    );
}

const mapStateToProps = ({ general, user }) => ({ general, user })
  
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ fetchAll }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Contributors);
