import { SET_ALL, SET_ORDERS, SET_PACKAGES, SET_PROJECTS, SET_USERNOS, SET_UTILITIES, SET_CONTRIBUTORS } from "../actions/constants";

const defaultState = {
  utilities: {},
  packages: [],
  projects: [],
  contributors: [],
  orders: [],
  userNos: 0
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_ALL:
      return { ...state, 
        packages: action.payload.packages,
        utilities: action.payload.utilities,
        userNos: action.payload.userNos,
        projects: action.payload.projects,
        orders: action.payload.orders,
        contributors: action.payload.contributors
        
      };
    case SET_PROJECTS:
      return { ...state, projects: action.payload };
    case SET_ORDERS:
      return { ...state, orders: action.payload };
    case SET_PACKAGES:
      return { ...state, packages: action.payload };
    case SET_USERNOS:
      return { ...state, userNos: action.payload };
    case SET_UTILITIES:
      return { ...state, utilities: action.payload };
    case SET_CONTRIBUTORS:
      return { ...state, contributors: action.payload };
    default:
      return state;
  }
};
