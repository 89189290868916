import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, Container, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loginUser, getUser, logoutUser } from 'src/redux/actions/user';
import Logo from 'src/components/Logo';
import { useHistory, Link } from 'react-router-dom';

const Login = ({ loginUser, getUser, logoutUser }) => {
  const [ isSubmitting, setIsSubmitting ] = useState(false)
  const [ error, setError ] = useState("")
  const history = useHistory()

  const handleLogin = async ( form ) => {
    const { email, password } = form
    if( email && password ) {
      setIsSubmitting(true)
      setError("")
      try {
        const data = await loginUser(form)
        const userData = await getUser(form.email)
        if(data.user && form.email == 'admin@gmt.com'){
            history.push('/dashboard')
        } 
        else if (userData?.contributor) {
            console.log('contributor')
            history.push('/dashboard')
        }
        else {        
            setError("Unauthorized")
            logoutUser();
        }
      }
      catch(err) {
        console.log(err)
        setError(err?.message || "An error has occurred")
      }
      finally {
        setIsSubmitting(false)
      }
    }
  }

  return (
    <>
        <Helmet>
            <title>Login | GMT Dashboard</title>
        </Helmet>
        <Box
            sx={{
                backgroundColor: 'background.default',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center'
            }}
        >
            <Container maxWidth="sm">
            <Formik
                initialValues={{
                    email: '',
                    password: ''
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                    password: Yup.string().max(255).required('Password is required')
                })}
                onSubmit={(e) => {
                    handleLogin(e);
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values
                }) => (
                <form onSubmit={handleSubmit}>
                    <Box sx={{ mb: 3 }}>
                    <Box style={{ display: 'flex', alignItems: 'center'}}>
                        <Logo type style={{ marginLeft: 0, marginRight: 10 }} />
                        <Typography style={{ marginTop: 10 }} color="textPrimary" variant="h3">Dashboard</Typography>
                    </Box>
                    <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="body2"
                    >
                        Sign in to you account dashboard
                    </Typography>
                    </Box>

                    <TextField
                        error={Boolean(touched.email && errors.email)}
                        fullWidth InputLabelProps={{ shrink: true }}
                        helperText={touched.email && errors.email}
                        label="Email Address"
                        margin="normal"
                        name="email"
                        placeholder="Enter email address"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="email"
                        value={values.email}
                        variant="outlined"
                    />
                    <TextField
                        error={Boolean(touched.password && errors.password)}
                        fullWidth InputLabelProps={{ shrink: true }}
                        helperText={touched.password && errors.password}
                        label="Password"
                        margin="normal"
                        name="password"
                        placeholder="Enter password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="password"
                        value={values.password}
                        variant="outlined"
                    />
                    {
                        error &&
                        <p style={{ color: 'brown', margin: '.5rem', textAlign: 'center' }} >{error}</p>
                    }
                    <Box sx={{ py: 2 }}>
                        <Button
                            color="primary"
                            disabled={isSubmitting}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                        >
                            Sign in
                        </Button>
                    </Box>
                    <Typography
                        color="textSecondary"
                        variant="body1" marginBottom={20}
                    >
                        Become a contributor
                        {' '}
                        <Link
                            to="/contributor"
                            variant="h6"
                        >
                            Create an account
                        </Link>
                    </Typography>
                </form>
                )}
            </Formik>
            </Container>
        </Box>
    </>
  );
};
const mapStateToProps = ({ user }) => ({ user });


const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ loginUser, getUser, logoutUser }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps )(Login);
