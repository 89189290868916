import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Box, Button, Checkbox, Container, CircularProgress, Card, Divider, CardActions, CardContent, Grid, IconButton, InputAdornment, TextField, Typography, FormControl } from '@material-ui/core';
import firebase from "../redux/api/config";
import { useState, useRef, useEffect } from 'react';
import { CameraAlt, Visibility, VisibilityOff } from '@material-ui/icons';
import { fetchUtilities } from '../redux/actions/general'
import axios from 'axios';
import { MultiSelect } from "react-multi-select-component";
import Logo from 'src/components/Logo';

const Register = ({ general, fetchUtilities }) => {
    const [ exists, setExists ] = useState(false)
    const [ banks, setBanks ] = useState([])
    const [ isSubmitting, setIsSubmitting ] = useState(false)
    const [ showPassword, setShowPassword ] = useState(false)
    const [ section, setSection ] = useState(0)
    const [ retrieving, setRetrieving ] = useState(false)
    const hiddenFileInput = useRef(null);
    const [utilities, setUtilities] = useState({});
    const [errors, setErrors] = useState({});
    const [ formValue, setFormValue ] = useState({
        email: '',
        name: '',
        image: '',
        phone: '',
        password: '',
        qualification: '',
        department: '',
        university: '',
        contentType: [],
        bank: '',
        accNumber: '',
        accName: '',
        policy: false
    })
    const contentTypes = ["neat notes", "summary of courses", "past questions", "past questions and answers", "articles/research papers",
        "handouts", "textbooks", "others"
    ]

    const handleFormChange = ({ name, value }) => {
        setFormValue({ ...formValue, [name]: value })
    }


    const emailSearch = async (email) => {
        firebase.firestore().collection('Users').where('email', '==', email).get().then((res) => {
            console.log(res.empty)
            setExists(res.empty ? false : true);
        })
    }

    const handleUpload = (target) => {
        handleFormChange({ name: 'image', value: target.files[0] })
    }

    const createContributor = async (e) => {
        e.preventDefault()
        setErrors({ message: '' })

        const { email, name, image, phone, password, qualification, department, university, contentType, bank, accNumber, accName, policy } = formValue
        
        if(email && password && name && phone && qualification && department && university && contentType.length > 0 && image && bank && accNumber && accName && policy && !isSubmitting ) {        
            setIsSubmitting(true)
            try {
                const payload = {
                    "business_name": formValue.email,
                    "bank_code": formValue.bank,
                    "account_number": formValue.accNumber,
                    "percentage_charge": 30
                }
                await axios.post('https://api.paystack.co/subaccount', payload, { headers: { 'Authorization': 'Bearer sk_live_b3a665c2d2122394edd796aa01429d95cbb3eb0e' } })
                .then(async (resp) => {
                    await firebase.auth().createUserWithEmailAndPassword(email, password).then(async (res) => {
                        const { image, password, ...values } = formValue
                        const date = Date.now();
                        await firebase.firestore().doc(`contributors/${res.user.uid}`).set({ ...values, contributor: true, registered: date, subAccount: resp?.data?.data?.subaccount_code, subAccountId: resp?.data?.data?.id  }).then(async () => {
                            res.user.sendEmailVerification();
                            const imageRef = firebase.storage().ref().child(`contributors/${res.user.uid}/profileImage`)
                            await imageRef.put(image, { contentType: 'image/jpeg' }).then(async () => {
                                await imageRef.getDownloadURL().then(async (downloadURL) => {
                                    await firebase.firestore().doc(`contributors/${res.user.uid}`).update({ photoURL: downloadURL })
                                    setSection(2);
                                    window.scrollTo(0,0);
                                    return true;
                                });
                            }).catch((err) => alert(err?.message || 'An error has occurred'))
                        }).catch((err) => alert(err?.message || 'An error has occurred'))
                    })
                }).catch((err) => alert(err?.message || 'An error has occurred'))
            }
            catch(err) {
                setErrors({ message: err.message })

            }
            finally {
                setIsSubmitting(false)
            }
        } else {
            setErrors({ message: 'All fields are required' })
        }
    }

    const init = async () => {
		try{
            await fetchUtilities();
            const res = await axios.get('https://api.paystack.co/bank');
            setBanks(res?.data?.data || [])
		}
		catch(err) {
		    console.log(err)
		}
	}

    const checkAccount = async () => {
        setErrors({ accNumber: '' })
        setRetrieving(true)
        try {
            const res = await axios.get(`https://api.paystack.co/bank/resolve?account_number=${formValue.accNumber}&bank_code=${formValue.bank}`, { headers: { 'Authorization': 'Bearer sk_live_b3a665c2d2122394edd796aa01429d95cbb3eb0e' } })
            handleFormChange({ name: 'accName', value: res?.data?.data?.account_name })
            setErrors({ accNumber: '' })
        } catch(err) {
            setErrors({ accNumber: 'Invalid Account Number' })
            handleFormChange({ name: 'accName', value: '' })
        }
        finally {
            setRetrieving(false)
        }
    }

    const getTypes = () => {
        const subs = contentTypes.map((type) => (
            { label: type, value: type }
        ))
        return subs || []
    }

    const setTypes = (e) => {
        const value = e.map(({ value }) => value)
        handleFormChange({ name: 'contentType', value })
    }

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
		setUtilities(general.utilities)
	}, [general])

  return (
        <>
            <Helmet>
                <title>Contributor Registration | GMT Dashboard</title>
            </Helmet>
            { 
                section < 2 && 
                <Box style={{ background: '#7927d3', color: '#fff' }} paddingY={5}>
                    <Container maxWidth="lg">
                        <Link to="/"><Logo style={{ marginLeft: 0, marginBottom: 10 }} /></Link>
                        <Typography variant="h1">
                            Become a contributor
                        </Typography>
                        <Typography gutterBottom variant="body2">
                            create a contributor account
                        </Typography>
                    </Container>
                </Box>
            }
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100%',
                }}
            >
                <Card>
                    <CardContent>
                        <Container maxWidth="lg" style={{ paddingTop: 25 }}>
                            {
                                section == 0 &&
                                <Box  marginBottom={10}>
                                    <Typography color="textPrimary" gutterBottom variant="h4">Earn 200k per semester by selling academic content on our platform.</Typography>

                                    <Typography color="textPrimary" gutterBottom>
                                        Wait .... there is no limit to the amount you can earn. Some contributors on our platform earn less while some others earn more than the amount mentioned. It all depends on your effort.
                                    </Typography>

                                    <Typography color="textPrimary" variant="h4">Why choose us ?</Typography>
                                    <Typography color="textPrimary" gutterBottom>
                                        <ul className="cList">
                                            <li>
                                                We offer 99% content protection whereby users cannot copy, share, print, download or even screenshot your content.<br/>
                                                This prevents piracy and unauthorized access.<br/>
                                                Case Study - Micheal, one of our contributors, have been selling the same content since 2018 till date ({new Date().getFullYear()})<br/>
                                                Thanks to this feature, content have remained "protected" for the past {new Date().getFullYear() - 2018} years.
                                            </li>
                                            <li>
                                                <span>Ubiquity</span><br/>
                                                Our app from which users would read your content is available on 
                                                <ul className="cList two">
                                                    <li>Apple App store</li>
                                                    <li>Google play store &amp;</li>
                                                    <li>Laptop app</li>
                                                </ul>

                                                So users can access your content anytime; anywhere from their mobile devices.
                                            </li>
                                            <li>
                                                <span>Offline access</span><br/>
                                                Users won't have to worry about network to read your content.<br/>
                                                They can download your materials WITHIN the app for offline access.
                                            </li>
                                        </ul>

                                    </Typography>

                                    <Typography color="textPrimary" variant="h4">Payout</Typography>
                                    <Typography color="textPrimary" gutterBottom>
                                        <ul className="cList th">
                                            <li>
                                                - We operate a 70:30 revenue split 
                                                <ul className="cList two">
                                                    <li>70% for contributors</li>

                                                    <li>30% for the platform </li>
                                                </ul>
                                            </li>
                                            <li>- All payments are made online, splitted and sent out according to the percentage.</li>
                                            <li>
                                                - Payouts are done on working days (excludes Saturday, Sunday &amp; Public holidays) <br/>
                                                <ul className="cList th">
                                                    <li>If for example a user buys your course on Monday, you would receive the payment on Tuesday.</li>
                                                    <li>If the payment was on Friday, you would receive the payout on Monday.</li>
                                                </ul>
                                            </li>
                                            <li>- Contributors have a dashboard for monitoring purchases.</li>
                                        </ul>
                                    </Typography>

                                    <Typography color="textPrimary" variant="h4">Affiliate Marketing</Typography>
                                    <Typography color="textPrimary" gutterBottom>
                                        To boost your sales we would introduce affiliate program, to enable influencers and others in your school promote your content. <br/>
                                        Affiliates would be paid 10% of the revenue. <br/>
                                        This 10% is taken from the contributor's 70% <br/>
                                    </Typography>
                                        
                                    <Button onClick={() => { setSection(1); window.scrollTo(0,0) }} variant="contained" style={{ textTransform:'inherit', margin: '.5rem auto' }}>Sign up as a contributor</Button>
                                    <Typography>
                                        <a href="https://gmt.com.ng/terms-and-conditions/" target="_blank">
                                            <small>Terms and Conditions</small>
                                        </a>
                                    </Typography>
                                </Box>
                            }
                            { 
                                section == 1 && 
                                <form onSubmit={(e) => createContributor(e)} class="contributor">                      
                                    <Typography marginTop={1} marginBottom={0} color="textPrimary" gutterBottom variant="h6">Personal Information</Typography>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <label>Profile Image</label>
                                            <div
                                                style={{ 
                                                    height: 200, width: 200, display: 'block', borderRadius: 10, margin: '  20px 0',
                                                    border: '2px solid rgb(133, 133, 133)', cursor: 'pointer'
                                                }}
                                                onClick={() => hiddenFileInput.current.click()}
                                            >
                                                { 
                                                    formValue.image ? 
                                                    <img
                                                        style={{ height: '100%', width: '100%', borderRadius: 10, objectFit: 'cover' }} 
                                                        src={URL.createObjectURL(formValue.image)} 
                                                    />
                                                    :
                                                    <CameraAlt style={{ margin: '10px', color: 'rgb(133, 133, 133)' }} />
                                                }
                                                <input accept=".jpg" type="file" hidden ref={hiddenFileInput} onChange={(e) => handleUpload(e.target) } />
                                            </div>
                                        </Grid>
                                        
                                        <Grid item xs={12} md={6} padding={2}>
                                            <label>Full name</label>
                                            <TextField
                                                fullWidth
                                                placeholder="Enter Full name"
                                                margin="normal"
                                                name="name"
                                                onChange={(e) => handleFormChange(e.target) }
                                                value={formValue.name}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} padding={2}>
                                            <label>EmailAddress</label>
                                            <TextField
                                                fullWidth
                                                placeholder="Enter Email Address"
                                                margin="normal"
                                                name="email"
                                                onChange={(e) => handleFormChange(e.target) }
                                                value={formValue.email}
                                                type="email"
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} padding={2}>
                                            <label>Phone Number</label>
                                            <TextField
                                                fullWidth
                                                placeholder="Enter Phone number"
                                                margin="normal"
                                                name="phone"
                                                onChange={(e) => handleFormChange(e.target) }
                                                value={formValue.phone}
                                                type="text"
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} padding={2}>
                                            <label>Password</label>
                                            <TextField
                                                fullWidth
                                                placeholder="Enter Password"
                                                margin="normal"
                                                name="password"
                                                type={showPassword ? 'text' : 'password'}
                                                onChange={(e) => handleFormChange(e.target) }
                                                value={formValue.password}
                                                variant="outlined"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() => setShowPassword(!showPassword)}
                                                                edge="end"
                                                            >
                                                                { !showPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    
                                    <Typography marginTop={2} marginBottom={0} color="textPrimary" gutterBottom variant="h6">Academic Information</Typography>
                                    <Grid container>
                                        <Grid item xs={12} md={6} padding={2}>
                                            <label>Academic Qualification</label>
                                            <TextField
                                                fullWidth
                                                select SelectProps={{ native: true }} InputLabelProps={{ shrink: true }}
                                                placeholder="Enter Academic Qualification"
                                                margin="normal"
                                                name="qualification"
                                                onChange={(e) => handleFormChange(e.target) }
                                                value={formValue.qualification}
                                                variant="outlined"
                                            >
                                                <option value="">Select an option</option>
                                                <option value="undergraduate">Undergraduate</option>
                                                <option value="bachelor's degree holder">Bachelor's Degree Holder</option>
                                                <option value="master's degree holder">Mater's Degree Holder</option>
                                                <option value="ph.D degree holder">Ph.D Degree Holder</option>
                                                <option value="professor">Professor</option>
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} md={6} padding={2}>
                                            <label>Department</label>
                                            <TextField
                                                fullWidth
                                                select SelectProps={{ native: true }} InputLabelProps={{ shrink: true }}
                                                placeholder="Enter Department"
                                                margin="normal"
                                                name="department"
                                                onChange={(e) => handleFormChange(e.target) }
                                                value={formValue.department}
                                                variant="outlined"
                                            >
                                                <option value="">Select an option</option>
                                                {
                                                    utilities.departments && utilities.departments.map((dept) => (
                                                        <option key={dept} value={dept}>
                                                            {dept}
                                                        </option>
                                                    ))
                                                }
                                            </TextField>
                                            <Typography fontSize="70%">Can't find your department? <a href="mailto:customersupport@gmt.com.ng">contact</a></Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6} padding={2}>
                                            <label>University</label>
                                            <TextField
                                                fullWidth
                                                select SelectProps={{ native: true }} InputLabelProps={{ shrink: true }}
                                                placeholder="Enter University"
                                                margin="normal"
                                                name="university"
                                                onChange={(e) => handleFormChange(e.target) }
                                                value={formValue.university}
                                                variant="outlined"
                                            >
                                                <option value="">Select an option</option>
                                                {
                                                    utilities.universities && utilities.universities.map((university) => (
                                                        <option key={university} value={university}>
                                                            {university}
                                                        </option>
                                                    ))
                                                }
                                            </TextField>
                                            <Typography fontSize="70%">Can't find your university? <a href="mailto:customersupport@gmt.com.ng">contact</a></Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6} padding={2}>
                                            <label>Content Type</label>
                                            <div className="multi-select">
                                                <MultiSelect
                                                    className="form-control"
                                                    options={getTypes()}
                                                    value={formValue.contentType.map((type) => ( { label: type, value: type } ))}
                                                    onChange={(e) => setTypes(e)}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                    
                                    <Typography marginTop={2} marginBottom={0} color="textPrimary" gutterBottom variant="h6">Payment Information</Typography>
                                    <Grid container>
                                        <Grid item xs={12} md={6} padding={2}>
                                            <label>Account Bank</label>
                                            <TextField
                                                fullWidth
                                                select SelectProps={{ native: true }} InputLabelProps={{ shrink: true }}
                                                placeholder="Enter Account Bank"
                                                margin="normal"
                                                name="bank"
                                                onBlur={(e) => checkAccount()}
                                                onChange={(e) => handleFormChange(e.target) }
                                                value={formValue.bank}
                                                variant="outlined"
                                            >
                                                <option value="">Select an option</option>
                                                {
                                                    banks.map(({code, name}, key) => (
                                                        <option key={key} value={code}>{name}</option>
                                                    ))
                                                }
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} md={6} padding={2}>
                                            <label>Account Number</label>
                                            <TextField
                                                error={Boolean(errors.accNumber)}
                                                fullWidth
                                                helperText={errors.accNumber}
                                                placeholder="Enter Account Number"
                                                margin="normal"
                                                name="accNumber"
                                                onBlur={(e) => checkAccount()}
                                                onChange={(e) => handleFormChange(e.target) }
                                                value={formValue.accNumber}
                                                variant="outlined"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            { retrieving ? <CircularProgress color="primary" size={15} /> : <></>}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} padding={2}>
                                            <label>Account Name</label>
                                            <TextField
                                                fullWidth
                                                placeholder="Enter Account Name"
                                                margin="normal"
                                                InputProps={{ readOnly: true }}
                                                name="accName"
                                                value={formValue.accName}
                                                variant="outlined"
                                            />
                                        </Grid>
                                    </Grid>
                                    
                                    <Box sx={{ alignItems: 'center', display: 'flex', ml: -1}}>
                                        <Checkbox
                                            checked={formValue.policy}
                                            name="policy" color="primary"
                                            onClick={() => handleFormChange({ name: 'policy', value: !formValue.policy }) }
                                        />
                                        <Typography
                                            color="textSecondary"
                                            variant="body1"
                                        >
                                            I have read and agreed with the
                                            {' '}
                                            <a color="primary" href="https://gmt.com.ng/terms-and-conditions/" target="_blank" variant="h6">
                                                Terms and Conditions
                                            </a>
                                        </Typography>
                                    </Box>
                                    
                                    <Box sx={{ py: 2 }}>

                                        <Button
                                            color="primary"
                                            disabled={isSubmitting}
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            style={{ textTransform: 'capitalize' }}
                                            variant="contained"
                                        >
                                            { isSubmitting ? <CircularProgress style={{ margin: '5px' }} color="inherit" size={20} /> : "Create contributor account" }
                                        </Button>
                                    </Box>
                                    {
                                        Boolean(errors?.message) && <Typography textAlign="center" style={{ color: 'brown' }} >{errors.message}</Typography>
                                    }
                                    <Typography
                                        color="textSecondary"
                                        variant="body1" marginBottom={20}
                                    >
                                        Have a contributor account?
                                        {' '}
                                        <Link
                                            to="/login"
                                            variant="h6"
                                        >
                                            Sign in
                                        </Link>
                                    </Typography>
                                </form>
                            }
                            {
                                section == 2 &&
                                <Box>
                                    <Typography
                                        align="center"
                                        color="textPrimary"
                                        variant="h1"
                                    >
                                        Registration successful
                                    </Typography>
                                    <Typography
                                        align="center"
                                        color="textPrimary"
                                        variant="subtitle2"
                                    >
                                    Kindly open your mailbox to verify your email address and then Login
                                    </Typography>
                                    <Typography
                                        align="center"
                                        color="textPrimary"
                                        variant="subtitle2"
                                        style={{ marginTop: '1rem' }}
                                    >
                                        <Link to="/login">Go to Login</Link>
                                    </Typography>
                                </Box>
                            }
                            
                        </Container>
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

const mapStateToProps = ({ general }) => ({ general });

const mapDispatchToProps = (dispatch) =>{
  return bindActionCreators({ fetchUtilities }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);