import { v4 as uuid } from 'uuid';
import moment from 'moment';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  IconButton,
  Icon,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { DescriptionOutlined, StickyNote2 } from '@material-ui/icons';


const LatestPackages = ({ packs, ...props }) => {

  return (
    <Card {...props}>
      <CardHeader
        subtitle={`${packs.length} in total`}
        title="Latest Packages"
      />
      <Divider />
      <List>
        {packs.slice().sort((a, b) => {
          var nameA = a.created
          var nameB = b.created
          if (nameA < nameB) {
            return 1;
          }
          if (nameA > nameB) {
            return -1;
          }
          return 0;
          }).map((pack, i) => (
          <ListItem
            divider={i < packs.length - 1}
            key={i}
          >
            <ListItemAvatar>
              <DescriptionOutlined />
            </ListItemAvatar>
            <ListItemText
              primary={
                <>
                  <Typography style={{ marginBottom: 5}} variant="h6">{pack.department} {pack.level} {pack.semester} {pack.type}</Typography>
                </>
              }
              secondary={
                <>
                  <Typography component="span" variant="body2" style={{ display: 'block', marginBottom: 7 }} color="textPrimary">
                    {pack.university}
                  </Typography>
                  <Typography component="span" variant="body2" style={{ display: 'block' }} color="textPrimary">
                    &#8358;{parseFloat(pack.price).toFixed(2)}
                  </Typography>

                </>
              }
            />
          </ListItem>
        ))}
      </List>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        <Button
          color="primary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
        >
          View all
        </Button>
      </Box>
    </Card>
  );
}

export default LatestPackages;
