import { SET_ALL, SET_PACKAGES, SET_PROJECTS, SET_USERNOS, SET_UTILITIES, SET_ORDERS, SET_CONTRIBUTORS } from "./constants";
import generalApi from "../api/general";
// import { encrypt } from '../util'

export const fetchAll = (contributor = '') => {
  return async (dispatch) => {
    try {
      const packages = await generalApi.fetchPackages(contributor);
      const projects = await generalApi.fetchProjects();
      const userNos = await generalApi.fetchUserNos();
      const utilities = await generalApi.fetchUtilities();
      const contributors = await generalApi.fetchContributors();
      const orders = await generalApi.fetchOrders(contributor);
      const payload = {
        packages, projects, userNos, utilities, orders, contributors
      }
      await dispatch({
        type: SET_ALL,
        payload: payload,
      });
      return Promise.resolve(payload);
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const fetchPackages = (contributor = '') => {
  return async (dispatch) => {
    try {
      const packages = await generalApi.fetchPackages(contributor);
      await dispatch({
        type: SET_PACKAGES,
        payload: packages,
      });
      return Promise.resolve(packages);
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const fetchContributors = () => {
  return async (dispatch) => {
    try {
      const contributors = await generalApi.fetchContributors();
      await dispatch({
        type: SET_CONTRIBUTORS,
        payload: contributors,
      });
      return Promise.resolve(contributors);
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const fetchOrders = (contributor = '') => {
  return async (dispatch) => {
    try {
      const orders = await generalApi.fetchOrders(contributor);
      await dispatch({
        type: SET_ORDERS,
        payload: orders,
      });
      return Promise.resolve(orders);
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const fetchProjects = () => {
  return async (dispatch) => {
    try {
      const projects = await generalApi.fetchProjects();

      await dispatch({
        type: SET_PROJECTS,
        payload: projects,
      });
      return Promise.resolve(projects);
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const fetchUserNos = () => {
  return async (dispatch) => {
    try {
      const userNos = await generalApi.fetchUserNos();

      await dispatch({
        type: SET_USERNOS,
        payload: userNos,
      });
      return Promise.resolve(userNos);
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const fetchUtilities = () => {
  return async (dispatch) => {
    try {
      const utilities = await generalApi.fetchUtilities();

      await dispatch({
        type: SET_UTILITIES,
        payload: utilities,
      });
      return Promise.resolve(utilities);
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

