import React, { useEffect, useState } from "react";
import Account from 'src/pages/Account';
import Contributors from 'src/pages/Contributors';
import Dashboard from 'src/pages/Dashboard';
import NotFound from 'src/pages/NotFound';
import Packages from 'src/pages/Packages';
import Settings from 'src/pages/Settings';
import EditPackage from 'src/pages/EditPackage';
import Login from 'src/pages/Login';
import { BrowserRouter as Router, Switch, Route, useHistory, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser } from "../redux/actions/user";
import Utilities from "src/pages/Utilities";
import firebase from '../redux//api/config'
import { fetchAll } from '../redux/actions/general'
import { bindActionCreators } from "redux";
import Orders from "src/pages/Orders";
import Register from "src/pages/Register";

const ScrollToTop = () => {
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [history.location.pathname]);

  return null;
};

const ResetScroll = withRouter(ScrollToTop);

const Routes = ({ user, fetchAll, logoutUser }) => {
    const [ usr, setUserData ] = useState(user)
    const fetchAllData = async () => {
        try{
            await fetchAll(usr?.userData?.uid);
        }
        catch( err) {
            console.log(err)
        }
        finally{
        }
    }

    const checkToken = async () => {
        firebase.auth().onAuthStateChanged(async (user) => {
            if(user) {
                console.log('no user')
                // await logoutUser();
            }
        })
    };

    useEffect(() => {
        checkToken()
        fetchAllData()
    }, [])

    useEffect(() => {
        setUserData(user)
    }, [user])

      
  const PrivateRoute = ({ component: Component, exact, ...rest }) => {
    const authenticated =  usr.authenticated;
    return (
      <Route
        {...rest}
        render={(props) =>
          authenticated ? <Component {...props} /> : <Redirect to="/login" />
        }
        exact={exact}
      />
    );
  };

  return (
    <Router>
      <ResetScroll />
      <Switch>
        <Route exact path="/">
          <Redirect to={`/dashboard`} />
        </Route>
        <Route exact path="/login" component={Login} />
        <Route exact path="/contributors" component={Register} />
        <Route exact path="/contributor" component={Register} />
        <Route exact path="/404" component={NotFound} />
        <PrivateRoute exact path="/packages" component={Packages} />
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute exact path="/contributors-list" component={Contributors} />
        <PrivateRoute exact path={`/packages/edit`} component={EditPackage} />
        <PrivateRoute exact path="/utilities" component={Utilities} />
        <PrivateRoute exact path="/orders" component={Orders} />
        <PrivateRoute exact path="/account" component={Account} />
        <PrivateRoute exact path="/settings" component={Settings} />
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Router>
  );
};


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ fetchAll, logoutUser }, dispatch)
  }
export default connect(({ user }) => ({ user }), mapDispatchToProps)(Routes);
