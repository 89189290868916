import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth';
import 'firebase/storage';

const FBConfig = {
  apiKey: "AIzaSyDh7by2NORcaJ3M6_DGUth0ZvfeRCoRxmU",
  authDomain: "gmt-edutech.firebaseapp.com",
  databaseURL: "https://gmt-edutech.firebaseio.com",
  projectId: "gmt-edutech",
  storageBucket: "gmt-edutech.appspot.com",
  messagingSenderId: "506648967711",
  appId: "1:506648967711:web:bdc18a2a6dff0ba1c0332a",
  measurementId: "G-DXNSDMWZ3N"
};

firebase.initializeApp(FBConfig);
firebase.firestore().settings({ timestampsInSnapshots: true })

export default firebase;