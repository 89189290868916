import AppLayout from 'src/components/AppLayout';
import { Helmet } from 'react-helmet';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Box, Avatar, Card, Divider, CardActions, CardContent, Button, Container, CircularProgress, FormHelperText, Grid, IconButton, InputAdornment, TextField, Typography, FormControl } from '@material-ui/core';
import firebase from "../redux/api/config";
import { useState, useRef, useEffect } from 'react';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { fetchUtilities } from '../redux/actions/general'
import axios from 'axios';
import { MultiSelect } from "react-multi-select-component";
import moment from 'moment';
import { getUser } from 'src/redux/actions/user';

const Account = ({ user, general, getUser, fetchUtilities }) => {
    const [ userData, setUserData ] = useState(user.userData);
    const [ exists, setExists ] = useState(false)
    const [ banks, setBanks ] = useState([])
    const [ isSubmitting, setIsSubmitting ] = useState(false)
    const [ showPassword, setShowPassword ] = useState(false)
    const [ section, setSection ] = useState(0)
    const [ retrieving, setRetrieving ] = useState(false)
    const hiddenFileInput = useRef(null);
    const [utilities, setUtilities] = useState({});
    const [errors, setErrors] = useState({});
    const [ formValue, setFormValue ] = useState({
        name: '',
        image: '',
        phone: '',
        qualification: '',
        department: '',
        university: '',
        contentType: [],
        bank: '',
        accNumber: '',
        accName: '',
    })
    const contentTypes = ["neat notes", "summary of courses", "past questions", "past questions and answers", "articles/research papers",
        "handouts", "textbooks", "others"
    ]
    
    const handleFormChange = ({ name, value }) => {
        setFormValue({ ...formValue, [name]: value })
    }

    const handleUpload = (target) => {
        handleFormChange({ name: 'image', value: target.files[0] })
    }

    const updateContributor = async (e) => {
        e.preventDefault()
        setErrors({ message: '' })
        const { name, image, phone, qualification, department, university, contentType, bank, accNumber, accName } = formValue
        if( name && phone && qualification && department && university && contentType.length > 0 && image && bank && accNumber && accName && !isSubmitting ) {        
            setIsSubmitting(true)
            try {
                const { image, ...values } = formValue
                const updated = Date.now();
                await firebase.firestore().doc(`contributors/${userData.uid}`).update({ ...values, updated  }).then(async () => {
                    if(typeof(image) !== 'string') {
                        const imageRef = firebase.storage().ref().child(`contributors/${userData.uid}/profileImage`)
                        await imageRef.put(image, { contentType: 'image/jpeg' }).then(async () => {
                            await imageRef.getDownloadURL().then(async (downloadURL) => {
                                await firebase.firestore().doc(`contributors/${userData.uid}`).update({ photoURL: downloadURL })
                                await updateSubAccount(userData.uid, userData.subAccountId);
                                await getUser(userData.email)
                                alert('Account updated successfully')
                            });
                        })
                    } else {
                        await updateSubAccount(userData.uid, userData.subAccountId);
                        await getUser(userData.email)
                        alert('Account updated successfully')
                    }
                })
            }
            catch(err) {
                setErrors({ message: err.message })
            }
            finally {
                setIsSubmitting(false)
            }
        } else {
            setErrors({ message: 'All fields are required' })
        }
    }

    
    const updateSubAccount = async (uid, id) => {
        const payload = {
            "bank_code": formValue.bank,
            "account_number": formValue.accNumber
        }
        await axios.put(`https://api.paystack.co/subaccount/${id}`, payload, { headers: { 'Authorization': 'Bearer sk_live_b3a665c2d2122394edd796aa01429d95cbb3eb0e', 'content-type': 'application/json' } })
        .then((res) => {
            firebase.firestore().doc(`contributors/${uid}`).update({ subAccount: res?.data?.data?.subaccount_code })
        }).catch((err) => console.log('error occurred', err))
    }

    const init = async () => {
		try{
            await fetchUtilities();
            const res = await axios.get('https://api.paystack.co/bank');
            setBanks(res?.data?.data || [])
		}
		catch(err) {
		    console.log(err)
		}
	}

    const checkAccount = async () => {
        handleFormChange({ name: 'accName', value: '' })
        setErrors({ accNumber: '' })
        setRetrieving(true)
        try {
            const res = await axios.get(`https://api.paystack.co/bank/resolve?account_number=${formValue.accNumber}&bank_code=${formValue.bank}`, { headers: { 'Authorization': 'Bearer sk_live_b3a665c2d2122394edd796aa01429d95cbb3eb0e' } })
            handleFormChange({ name: 'accName', value: res?.data?.data?.account_name })
            setErrors({ accNumber: '' })
        } catch(err) {
            setErrors({ accNumber: 'Invalid Account Number' })
        }
        finally {
            setRetrieving(false)
        }
    }

    const getTypes = () => {
        const subs = contentTypes.map((type) => (
            { label: type, value: type }
        ))
        return subs || []
    }

    const setTypes = (e) => {
        const value = e.map(({ value }) => value)
        handleFormChange({ name: 'contentType', value })
    }

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
		setUtilities(general.utilities)
	}, [general])

    useEffect(() => {
        setUserData(user.userData)
    }, [user])

    useEffect(() => {
        setFormValue({
            name: userData?.name || '',
            image: userData?.photoURL || '',
            phone: userData?.phone || '',
            qualification: userData?.qualification || '',
            department: userData?.department || '',
            university: userData?.university || '',
            contentType: userData?.contentType || [],
            bank: userData?.bank || '',
            accNumber: userData?.accNumber || '',
            accName: userData?.accName || '',
        })
    }, [userData])

    return (
        <AppLayout>
            <Helmet>
                <title>Account | GMT Admin</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }} marginBottom={5}>
                <Container maxWidth="lg">
                    <form onSubmit={(e) => updateContributor(e)} class="contributor-edit">                      
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Card>
                                    <CardContent>
                                        <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                                            <div style={{ cursor:'pointer' }}>
                                                { 
                                                    typeof(formValue.image) !== 'string' ? 
                                                    <Avatar sx={{ height: 200, width: 200, marginBottom: 1}}>
                                                        <img 
                                                            style={{ height: '100%', width: '100%', objectFit: 'cover' }} 
                                                            src={URL.createObjectURL(formValue.image)} 
                                                        />
                                                    </Avatar>
                                                    :
                                                    <Avatar src={userData?.photoURL} sx={{ height: 200, width: 200, marginBottom: 1}} onClick={() => hiddenFileInput.current.click()}/>
                                                }
                                            </div>
                                            <input accept=".jpg" type="file" hidden ref={hiddenFileInput} onChange={(e) => handleUpload(e.target) } />

                                            <Typography color="textPrimary" gutterBottom variant="h3">
                                                {userData?.name}
                                            </Typography>
                                            <Typography color="textSecondary" variant="body1" style={{ textTransform: 'lowercase'}}>
                                                {`${userData?.email}`}
                                            </Typography>
            
                                            <Typography
                                            color="textSecondary"
                                            variant="body1"
                                            >
                                            {`Registered: ${moment(userData?.registered).format('DD MMMM, YYYY')}`}
                                            </Typography>
                                        </Box>

                                        <Typography marginTop={1} marginBottom={0} color="textPrimary" gutterBottom variant="h6">Personal Information</Typography>
                                        <Grid container>
                                            
                                            <Grid item xs={12} md={6} paddingX={1}>
                                                <label>Full Name</label>
                                                <TextField
                                                    fullWidth
                                                    placeholder="Enter Full name"
                                                    margin="normal"
                                                    name="name"
                                                    onChange={(e) => handleFormChange(e.target) }
                                                    value={formValue.name}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} paddingX={1}>
                                                <label>Phone Number</label>
                                                <TextField
                                                    fullWidth
                                                    placeholder="Enter Phone number"
                                                    margin="normal"
                                                    name="phone"
                                                    onChange={(e) => handleFormChange(e.target) }
                                                    value={formValue.phone}
                                                    type="text"
                                                    variant="outlined"
                                                />
                                            </Grid>
                                        </Grid>
                                        
                                        <Typography marginTop={2} marginBottom={0} color="textPrimary" gutterBottom variant="h6">Academic Information</Typography>
                                        <Grid container>
                                            <Grid item xs={12} md={6} paddingX={1}>
                                                <label>Academic Qualification</label>
                                                <TextField
                                                    fullWidth
                                                    select SelectProps={{ native: true }} InputLabelProps={{ shrink: true }}
                                                    placeholder="Enter Academic Qualification"
                                                    margin="normal"
                                                    name="qualification"
                                                    onChange={(e) => handleFormChange(e.target) }
                                                    value={formValue.qualification}
                                                    variant="outlined"
                                                >
                                                    <option value="">Select an option</option>
                                                    <option value="undergraduate">Undergraduate</option>
                                                    <option value="bachelor's degree holder">Bachelor's Degree Holder</option>
                                                    <option value="master's degree holder">Mater's Degree Holder</option>
                                                    <option value="ph.D degree holder">Ph.D Degree Holder</option>
                                                    <option value="professor">Professor</option>
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12} md={6} paddingX={1}>
                                                <label>Department</label>
                                                <TextField
                                                    fullWidth
                                                    select SelectProps={{ native: true }} InputLabelProps={{ shrink: true }}
                                                    placeholder="Enter Department"
                                                    margin="normal"
                                                    name="department"
                                                    onChange={(e) => handleFormChange(e.target) }
                                                    value={formValue.department}
                                                    variant="outlined"
                                                >
                                                    <option value="">Select an option</option>
                                                    {
                                                        utilities.departments && utilities.departments.map((dept) => (
                                                            <option key={dept} value={dept}>
                                                                {dept}
                                                            </option>
                                                        ))
                                                    }
                                                </TextField>
                                                <Typography fontSize="70%">Can't find your department? <a href="mailto:customersupport@gmt.com.ng">contact</a></Typography>
                                            </Grid>
                                            <Grid item xs={12} md={6} paddingX={1}>
                                                <label>University</label>
                                                <TextField
                                                    fullWidth
                                                    select SelectProps={{ native: true }} InputLabelProps={{ shrink: true }}
                                                    placeholder="Enter University"
                                                    margin="normal"
                                                    name="university"
                                                    onChange={(e) => handleFormChange(e.target) }
                                                    value={formValue.university}
                                                    variant="outlined"
                                                >
                                                    <option value="">Select an option</option>
                                                    {
                                                        utilities.universities && utilities.universities.map((university) => (
                                                            <option key={university} value={university}>
                                                                {university}
                                                            </option>
                                                        ))
                                                    }
                                                </TextField>
                                                <Typography fontSize="70%">Can't find your university? <a href="mailto:customersupport@gmt.com.ng">contact</a></Typography>
                                            </Grid>
                                            <Grid item xs={12} md={6} paddingX={1}>
                                                <label>Content Type</label>
                                                <div className="multi-select">
                                                    <MultiSelect
                                                        className="form-control"
                                                        options={getTypes()}
                                                        value={formValue.contentType.map((type) => ( { label: type, value: type } ))}
                                                        onChange={(e) => setTypes(e)}
                                                        placeholder="Enter Select an option"
                                                        labelledBy="Select an option"
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                        
                                        <Typography marginTop={2} marginBottom={0} color="textPrimary" gutterBottom variant="h6">Payment Information</Typography>
                                        <Grid container>
                                            <Grid item xs={12} md={6} paddingX={1}>
                                                <label>Account Bank</label>
                                                <TextField
                                                    fullWidth
                                                    select SelectProps={{ native: true }} InputLabelProps={{ shrink: true }}
                                                    placeholder="Enter Account Bank"
                                                    margin="normal"
                                                    name="bank"
                                                    onBlur={(e) => checkAccount()}
                                                    onChange={(e) => handleFormChange(e.target) }
                                                    value={formValue.bank}
                                                    variant="outlined"
                                                >
                                                    <option value="">Select an option</option>
                                                    {
                                                        banks.map(({code, name}, key) => (
                                                            <option key={key} value={code}>{name}</option>
                                                        ))
                                                    }
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12} md={6} paddingX={1}>
                                                <label>Account Number</label>
                                                <TextField
                                                    error={Boolean(errors.accNumber)}
                                                    fullWidth
                                                    helperText={errors.accNumber}
                                                    placeholder="Enter Account Number"
                                                    margin="normal"
                                                    name="accNumber"
                                                    onBlur={(e) => checkAccount()}
                                                    onChange={(e) => handleFormChange(e.target) }
                                                    value={formValue.accNumber}
                                                    variant="outlined"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                { retrieving ? <CircularProgress color="primary" size={15} /> : <></>}
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} paddingX={1}>
                                                <label>Account Name</label>
                                                <TextField
                                                    fullWidth
                                                    placeholder="Enter Account Name"
                                                    margin="normal"
                                                    InputProps={{ readOnly: true }}
                                                    name="accName"
                                                    value={formValue.accName}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                        </Grid>
                                        
                                        {
                                            Boolean(errors?.message) && <Typography textAlign="center" style={{ color: 'brown' }} >{errors.message}</Typography>
                                        }
                                    </CardContent>
                                    <Divider />
                                    <CardActions>
                                        <Button
                                            color="primary"
                                            disabled={isSubmitting}
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            style={{ textTransform: 'capitalize' }}
                                            variant="contained"
                                        >
                                            { isSubmitting ? <CircularProgress style={{ margin: '5px' }} color="inherit" size={20} /> : "Update account" }
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        </Grid>
                    </form>
                </Container>
            </Box>
        </AppLayout>
    )
};

const mapStateToProps = ({ general, user }) => ({ general, user });

const mapDispatchToProps = (dispatch) =>{
  return bindActionCreators({ fetchUtilities, getUser }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Account);