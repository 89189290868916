import firebase from "./config";

export default {
    async fetchPackages(contributor = '') {
        var packages = [];
        if(contributor) {
            await firebase.firestore().collection('packages').where('contributor', '==', contributor).get().then(async (resp)=> {
                resp.docs.map((res) => {
                    let pack = res.data();
                    pack["id"] = res.id;
                    packages.push(pack)
                })
            })
        } else {
            await firebase.firestore().collection('packages').get().then(async (resp)=> {
                resp.docs.map((res) => {
                    let pack = res.data();
                    pack["id"] = res.id;
                    packages.push(pack)
                })
            })
        }
        return packages;
    },
    async fetchOrders(contributor = '') {
        var orders = [];
        if(contributor) {
            await firebase.firestore().collection('orders').where('uid', '==', contributor).get().then(async (resp)=> {
                resp.docs.map((res) => {
                    let order = res.data();
                    order["orderId"] = res.id;
                    orders.push(order)
                })
            })
        } else {
            await firebase.firestore().collection('orders').get().then(async (resp)=> {
                resp.docs.map((res) => {
                    let order = res.data();
                    order["orderId"] = res.id;
                    orders.push(order)
                })
            })
        }
        return orders;
    },
    async fetchContributors() {
        var contributors = [];
        await firebase.firestore().collection('contributors').get().then(async (res)=> {
            res.docs.map((res) => {
                contributors.push(res.data())
            })
        })
        return contributors;
    },
    async fetchProjects() {
        var projects = [];
        await firebase.firestore().collection('projects').get().then(async (res)=> {
            res.docs.map((res) => {
                projects.push(res.data())
            })
        })
        return projects;
    },
    async fetchUserNos() {
        var no;
        await firebase.firestore().collection('Users').get().then(async (res)=> {
            no = res.docs.length
        })
        return no ? no - 1 : 0;
    },
    async fetchUtilities () {
        var utilities
        var users = []
        await firebase.firestore().doc('utilities/general').get().then(async (res) => {
            utilities = res.data();
            try {
                await firebase.firestore().collection('Users').get().then((res)=> {
                    res.docs.forEach((doc) => {
                        users.push({...doc.data(), uid: doc.id})
                    })
                })
                utilities['users'] = users
            } catch(err) { console.log(err)}
        })
        return utilities;
    },

    async addReview(payload) {
        const added = Date.now();
        return firebase.firestore().collection('reviews').add({ ...payload, added });
    },
    async addReview(payload) {
        const added = Date.now();
        return firebase.firestore().collection('reviews').add({ ...payload, added });
    },
    async getPackageReviews(pid) {
        return firebase.firestore().collection(`reviews`).where('pid', '==', pid).get();
    },
};

