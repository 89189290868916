import { USER_LOGIN, USER_LOGOUT, USER_DATA } from "./constants";
import user from "../api/user";

export const loginUser = (payload, checked) => {
  return async (dispatch) => {
    try {
      const response = await user.loginUser(payload);
      localStorage.setItem("gmt_admin", JSON.stringify(response.user));
      await dispatch({
        type: USER_LOGIN,
        payload: response.user,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const getUser = (email) => {
  return async (dispatch) => {
    try {
      const response = await user.getUser(email);
      let data;
      if(response.empty) {
          data = {};
      } else { data = response.docs[0].data(); data['uid'] = response.docs[0].id  }
      localStorage.setItem("gmt_admin", JSON.stringify(data));
      await dispatch({
        type: USER_DATA,
        payload: data,
      });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const logoutUser = () => {
  return async (dispatch) => {
    try {
      localStorage.removeItem("gmt_admin");
      try {
        await user.logoutUser();
      } catch(err) { console.log(err) }
      await dispatch({
        type: USER_LOGOUT
      });
      return Promise.resolve(true);
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  };
};
