import React, { useState, useEffect, useRef } from 'react'
import AppLayout from 'src/components/AppLayout'
import { useHistory } from 'react-router'
import { 
    Box, Grid, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Chip, InputLabel,
    Button, TextField, Typography, ListItem, Tooltip, TableSortLabel, Card, ListItemSecondaryAction, IconButton, ListItemText, List, ListItemIcon,
    Accordion, AccordionDetails, AccordionSummary, AccordionActions, Divider, Table, TableHead, TableBody, TableRow, TableCell, CircularProgress
  } from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Publish, Delete, Edit, PictureAsPdf, Person, LibraryMusic, ExpandMore, StayCurrentLandscapeSharp } from '@material-ui/icons';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchPackages, fetchUtilities } from '../redux/actions/general'
import firebase from "../redux/api/config";
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment'
import { capitalize } from '../utils/index'
import generalApi from '../redux/api/general'

const useStyles = makeStyles((theme) => ({
  select: {
    textTransform: 'capitalize',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  helper: {
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

  
const EditPackage = ({ general, fetchPackages, fetchUtilities, user, ...props }) => {
    const history = useHistory()
	const classes = useStyles();	
    const hiddenFileInput = useRef(null);
	const hiddenAudioFileInput = useRef(null);
    const [ userData, setUserData ] = useState(user.userData)
    const newCourse = {
		title: '',
		materials: [],
		features: [],
		audios: [],
		references: []
	}
    
	const newSub = {
		email: '', //previously userID but would use Email
		paymentRef: "",
		amount: "",
		orderType: "", // 1 or 2 or 3
		paymentDate: "",
		userId: '',
        deviceTypes: []
	}
    if(!props.location.state?.pack){
        history.push('/packages')
    }
	const [utilities, setUtilities] = useState({});
	const [orders, setOrders] = useState([]);
    
    const [pack, setPack] = useState(props.location.state?.pack)
    const [newPack, setNewPack] = useState(props.location.state?.newPack)

    const [packDetails, setPackDetails] = useState({
        type: pack.type || '',
		semester: pack.semester || '',
		department: pack.department || '',
		level: pack.level || '',
		price: pack.price || '',
		university: pack.university || '',
		description: pack.description || '',
    })
    const [packCourses, setPackCourses] = useState(pack.courses || [])
    const [packSubscribers, setPackSubscribers] = useState(pack.subscribers || [])
	const [processing, setProcessing] = useState('');

    const [ reviews, setReviews ] = useState([])
    const [ addingReview, setAddingReview ] = useState(false)
    const [ reviewError, setReviewError ] = useState('')
    const [ reviewModal, setReviewModal ] = useState(false)
    const [ reviewForm, setReviewForm ] = useState({ message: '', rating: 0, name: '' })

    // collect input values for pack details
    const handleChange = (event) => {
        setPackDetails({
            ...packDetails,
            [event.target.name]: event.target.value
		});
	};

    const getDate = (value) => {
        return moment(value).format('DD/MM/YYYY')
    }

	const [edit, setEdit] = useState(null);
	const [packToEdit, setPackToEdit] = useState(null);

	const [fetching, setFetching] = useState(false);

	
	const [ subscriberDetails, setSubscriberDetails ] = useState("")
	const [ subscriberDetailsError, setSubscriberDetailsError ] = useState("")

	const [ newSubscriber, setNewSubscriber ] = useState(newSub)

	const setSubscriberValue = (name, value) => {
        if(name === 'orderType' && value < '3') {
            setNewSubscriber({ ...newSubscriber, [name]: value, deviceTypes: []})
        } else if (name === 'orderType' && value === '3') {
            setNewSubscriber({ ...newSubscriber, [name]: value, deviceTypes: ['android', 'ios', 'laptop']})
        } else {
            setNewSubscriber({
                ...newSubscriber,
                [name]: value
            });
        }
	}

	const [ subModal, setSubModal ] = useState(false)
	const [ addingSub, setAddingSub ] = useState(false)

	const [ toBeDeleted, setToBeDeleted ] = useState([])
	const [ toBeDeletedAudios, setToBeDeletedAudios ] = useState([])

	const [adding, setAdding] = useState(false);
	
	const [packages, setPackages] = useState([]);

	const [ materialTitle, setMaterialTitle ] = useState('')
	const [ material, setMaterial ] = useState()

	const [ audioTitle, setAudioTitle ] = useState('')
	const [ audio, setAudio ] = useState()

	const [ progress, setProgress ] = useState(null)
	const [open, setOpen] = useState(false);

	const [deleteModal, setDeleteModal] = useState(false);
	const [deleting, setDeleting] = useState(false);
    
	const [ alertAction, setAlertActionModal] = useState(null);
	const [ alertProcessing, setAlertProcessing ] = useState(false);


	const init = async () => {
		setFetching(true);
		try{
            await fetchPackages(userData?.uid);
            await fetchUtilities();
		}
		catch(err) {
		    console.log(err)
		}
		finally {
		    setFetching(false)
		}
	}
    
    const setCourseTitle = (key, value) => {
        let arr = [ ...packCourses ]
        let object = arr[key]
        object.title = value;
        arr[key] = object
        setPackCourses(arr);
    }

    const checkCourseTitle = (title, index) => {
        const res = (packCourses.find((course, key) => course.title == title && key !== index));
		return Boolean(res) ? true: false
	}


    const addToFeatures = (key, feature) => {
        let arr = [ ...packCourses ]
        let features = arr[key].features
        features.push(feature)
        arr[key].features = features
        setPackCourses(arr);
    }

    const addToReferences = (key, reference) => {
        let arr = [ ...packCourses ]
        let references = arr[key].references
        references.push(reference)
        arr[key].references = references
        setPackCourses(arr);
    }
        
	const checkTitle = (key) => {
		return (packCourses[key].materials.map(mat => { return mat.title }).indexOf( materialTitle.trim() ) >= 0) ? true: false
	}

    const getReviews = async () => {
        await generalApi.getPackageReviews(pack.id).then((res) => {
            setReviews(res.empty ? [] : res.docs.map(doc => doc.data()))
        }).catch(err => console.log(err))
    }

    const addReview = async () => {
        setReviewError('')
        if(reviewForm.message && reviewForm.name && reviewForm.rating > 0) {
            setAddingReview(true)
            const payload = {
                ...reviewForm, pid: pack.id, uid: ''
            }
            await generalApi.addReview(payload).then(async () => {
                await getReviews()
                setReviewModal(false)
            }).catch(err => setReviewError(err?.message || 'An error occurred')).finally(() => setAddingReview(false))
        } else {
            setReviewError('All fields are required')
        }
    }

	/*
		Audio Upload Process 
	*/
	const addAudioMaterial = (key) => {
		if (audioTitle && audio ) {
			const audioItem = {
				title: audioTitle,
				file: audio
			}
			let arr = [ ...packCourses ]
			let audios = arr[key].audios ? arr[key].audios : []
			audios.push(audioItem)
			arr[key]['audios'] = audios
			setPackCourses(arr);

			setAudioTitle('')
			setAudio(null)
		} else {
			alert('Select an Audio and add a title')
		}
	}

	const handleAudioUpload = (target) => {
		setAudio(target.files[0])
	}

	const deleteAudio = (key, index, mat, id, title) => {
		if(mat && id) {
			mat['course'] = title;
			let arr = [ ...packCourses ]
			let audios = arr[key].audios.filter((audio, audioKey) => audioKey != index)
			arr[key].audios = audios
			setPackCourses(arr);
			let del = toBeDeletedAudios;
			del.push(mat)
			setToBeDeletedAudios(del)
		} else {
			let arr = [ ...packCourses ]
			let audios = arr[key].audios.filter((audio, audioKey) => audioKey != index)
			arr[key].audios = audios
			setPackCourses(arr);
		}
	}
	
	const deleteOnlineAudios = () => {
		toBeDeleted.forEach((mat) => {
			const desertRef = firebase.storage().ref().child(`materials/${edit}/${mat.course.replace("/", "-")}/audios/${mat.title}`);
			// Delete the file
			desertRef.delete().then(() => {
				console.log("deleted");
			}).catch((error) => {
                console.log(error)
			});
		})
	}

	const addMaterial = (key) => {
		if (materialTitle && material ) {
		const materialItem = {
			title: materialTitle,
			file: material
		}
		let arr = [ ...packCourses ]
		let materials = arr[key].materials
		materials.push(materialItem)
		arr[key].materials = materials
		setPackCourses(arr);

		setMaterialTitle('')
		setMaterial(null)
		} else {
		alert('Select a Material and add a title')
		}
	}

	const handleUpload = (target) => {
		setMaterial(target.files[0])
	}

	const deleteFeature = (key, index) => {
		let arr = [ ...packCourses ]
		let features = arr[key].features.filter((feature, featureKey) => featureKey != index)
		arr[key].features = features
		setPackCourses(arr);
	}
	
	const deleteReference = (key, index) => {
		let arr = [ ...packCourses ]
		let references = arr[key].references.filter((reference, referenceKey) => referenceKey != index)
		arr[key].references = references
		setPackCourses(arr);
	}

	const deleteMaterial = (key, index, mat, id, title) => {
		if(mat && id) {
		mat['course'] = title;
		let arr = [ ...packCourses ]
		let materials = arr[key].materials.filter((material, materialKey) => materialKey != index)
		arr[key].materials = materials
		setPackCourses(arr);
		let del = toBeDeleted;
		del.push(mat)
		setToBeDeleted(del)
		} else {
		let arr = [ ...packCourses ]
		let materials = arr[key].materials.filter((material, materialKey) => materialKey != index)
		arr[key].materials = materials
		setPackCourses(arr);
		}
	}

	const deleteOnlineMaterials = () => {
		toBeDeleted.forEach((mat) => {
		const desertRef = firebase.storage().ref().child(`materials/${edit}/${mat.course.replace("/", "-")}/${mat.title}`);
		// Delete the file
		desertRef.delete().then(() => {
			console.log("deleted");
		}).catch((error) => {
			const desertRef2 = firebase.storage().ref().child(`materials/${edit}/${mat.title}`);
			desertRef2.delete().catch(() => {
			console.log('error')
			});
			// Uh-oh, an error occurred!
		});
		})
	}

	const deleteCourse = (key) => {
		packCourses[key].materials.map((mat, index) => {
		deleteMaterial(key, index, mat, edit)
		})
		let arr = packCourses.filter((course, courseKey) => courseKey != key)
		setPackCourses(arr);
	}
  
  	const addNewPack = async () => {
		const { type, university, department, level, price, semester, description } = packDetails
		const coursesCheck = await packCourses.map(({title}) => {
            if(title == ''){
                alert('All Courses must have Materials and Features')
                return false;
            }
            else return true
		})[0]

		if( type && university && department && level && price && semester && description && coursesCheck && adding == false ) {
            if(
                (packages.filter(
                (pack) => 
                pack.level == level && pack.type == type && pack.semester == semester && pack.university == university && pack.department == department
                ).length > 0) && !edit
            ) {
                alert('Package exists, please update the existing one')
                return;
            }
            else {
                setAdding(true)
                const payload = {
                    type, university, department, level, price, semester, packCourses, description
                }
                try {
                    const contributor = userData?.contributor ? userData?.uid : '' || ''
                    await uploadPackage(payload, contributor)
                }
                catch(err) {
                    console.log(err)
                }
                finally {
                
                }
            }
		} else {
            alert('All Package Fields are required')
            setAdding(false)
		}
  	}

	const uploadPackage = async (payload, contributor = '') => {
		var created = Date.now();
        await firebase.firestore().collection('packages').add({
            type: payload.type,
            level: payload.level,
            semester: payload.semester,
            university: payload.university,
            department: payload.department,
            price: payload.price,
            description: payload.description,
            created,
        }).then(async (ref) => {
            let id = ref.id
            if(contributor) firebase.firestore().doc(`packages/${id}`).update({ contributor }).catch((err) =>console.log(err));
            await uploadMaterials(id)
        })
	}

	const uploadMaterials = async (newPackId = '') => {
        setAdding(true)
        const id = newPackId ? newPackId : pack.id
		var cor = 0
        var newCourses = [...packCourses]
        try {
            await packCourses.forEach(async (course, index)=>{
                var mats = []
                if(course.materials.length > 0) {
                    const res = await course.materials.forEach(async({ title, file }, key) => {
                        if(typeof(file) !== "string") {
                            var materialRef = firebase.storage().ref().child(`materials/${id}/${course.title.replace("/", "-")}/${title}`);
                            const resp =  materialRef.put(file, { contentType: 'application/pdf'} ).on(firebase.storage.TaskEvent.STATE_CHANGED,
                                (snapshot) => {
                                setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                                switch (snapshot.state) {
                                case firebase.storage.TaskState.PAUSED: // or 'paused'
                                    console.log('Upload is paused');
                                    break;
                                case firebase.storage.TaskState.RUNNING: // or 'running'
                                    console.log('Upload is running');
                                    break;
                                }
                                }, 
                                (error) => {
                                switch (error.code) {
                                    case 'storage/unauthorized':
                                    console.log('Unauthorized')
                                    break;
                                    case 'storage/canceled':
                                    console.log('cancelled')
                                    break;
                                    case 'storage/unknown':
                                    console.log('unknown error')
                                    break;
                                }
                                },
                                () => {
                                    materialRef.getDownloadURL().then((downloadURL) => {
                                        mats.push({ title, file: downloadURL })
                                        if(mats.length === course.materials.length) {
                                            newCourses[index].materials = mats
                                            cor += 1;
                                            if(cor == packCourses.length){
                                                uploadAudios(newCourses, id)
                                                deleteOnlineMaterials()
                                                setToBeDeleted([])
                                            }
                                        }
                                    });
                                }
                            )
                        }
                        else if(typeof(file) === "string") {
                            mats.push({ title, file })
                            if(mats.length === course.materials.length) {
                                newCourses[index].materials = mats
                                cor += 1;
                                if(cor == packCourses.length){
                                    uploadAudios(newCourses, id)
                                    deleteOnlineMaterials()
                                    setToBeDeleted([])
                                }
                            }
                        }
                    })
                } else {
                    newCourses[index].materials = mats
                    cor += 1;
                    if(cor == packCourses.length){
                        uploadAudios(newCourses, id)
                        deleteOnlineMaterials()
                        setToBeDeleted([])
                    }
                }
            })
        }
        catch (err) { 
            setAdding(false)
        }
	}

    const uploadAudios = async (newCourses, id) => {
		var cor = 0
        try {
            await packCourses.forEach(async (course, index)=>{
                var audios = []
                course['audios'] = course.hasOwnProperty('audios') ? course.audios : [];
                if(course.audios.length > 0) {
                    const res2 = await course.audios.forEach(async({ title, file }, key) => {
                        if(typeof(file) !== "string") {

                            var audioRef = firebase.storage().ref().child(`materials/${id}/${course.title.replace("/", "-")}/audios/${title}`);
                            const resp =  audioRef.put(file, { contentType: 'audio/mp3'} ).on(firebase.storage.TaskEvent.STATE_CHANGED,
                                (snapshot) => {
                                setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                                switch (snapshot.state) {
                                case firebase.storage.TaskState.PAUSED: // or 'paused'
                                    console.log('Upload is paused');
                                    break;
                                case firebase.storage.TaskState.RUNNING: // or 'running'
                                    console.log('Upload is running');
                                    break;
                                }
                                }, 
                                (error) => {
                                switch (error.code) {
                                    case 'storage/unauthorized':
                                    console.log('Unauthorized')
                                    break;
                                    case 'storage/canceled':
                                    console.log('cancelled')
                                    break;
                                    case 'storage/unknown':
                                    console.log('unknown error')
                                    break;
                                }
                                },
                                () => {
                                    audioRef.getDownloadURL().then(async (downloadURL) => {
                                        audios.push({ title, file: downloadURL })
                                        if(audios.length === course.audios.length) {
                                            newCourses[index].audios = audios
                                            cor += 1;
                                            if(cor == packCourses.length){
                                                await setDBCourses(id, newCourses)
                                                deleteOnlineAudios()
                                                setAdding(false)
                                                setToBeDeletedAudios([])
                                                if(newPack) history.push('/packages')
                                                else { 
                                                    init()
                                                    setAlertActionModal('successful') 
                                                }
                                            }
                                        }
                                    });
                                }
                            )
                        }
                        else if(typeof(file) === "string") {
                            audios.push({ title, file })
                            if(audios.length === course.audios.length) {
                                newCourses[index].audios = audios
                                cor += 1;
                                if(cor == packCourses.length) {
                                    await setDBCourses(id, newCourses)
                                    deleteOnlineAudios()
                                    setAdding(false)
                                    setToBeDeletedAudios([])
                                    if(newPack) history.push('/packages')
                                    else { 
                                        init()
                                        setAlertActionModal('successful') 
                                    }
                                }
                            }
                        }
                    })
                }
                else {
                    newCourses[index].audios = audios
                    cor += 1;
                    if(cor == packCourses.length){
                        await setDBCourses(id, newCourses)
                        deleteOnlineAudios()
                        setAdding(false)
                        setToBeDeletedAudios([])
                        if(newPack) history.push('/packages')
                        else { 
                            init()
                            setAlertActionModal('successful') 
                        }
                    }
                }

            })
        }
        catch (err) {
            console.log('audio error', err)
            setAdding(false)
        }
    }

    const deletePackage = async () => {
		setDeleting(true)
		try {
            await firebase.firestore().doc(`packages/${pack.id}`).delete().then(async () => {
                await pack.courses.map(async (course) => {
                    course.materials.forEach((material) => {
                        const desertRef = firebase.storage().ref().child(`materials/${pack.id}/${course.title.replace("/", "-")}/${material.title}`);
                        desertRef.delete().then(() => {
                            console.log("deleted");
                        }).catch((error) => {
                            const desertRef = firebase.storage().ref().child(`materials/${pack.id}/${material.title}`);
                            desertRef.delete().catch(err => console.log('error'))
                        // Uh-oh, an error occurred!
                        });
                    })
                    course.audios.forEach((audio) => {
                        const desertRef = firebase.storage().ref().child(`materials/${pack.id}/${course.title.replace("/", "-")}/audios/${audio.title}`);
                        desertRef.delete().then(() => {
                            console.log("deleted");
                        }).catch((error) => {
                            console.log(error)
                        // Uh-oh, an error occurred!
                        });
                    })
                    await firebase.firestore().collection('reviews').where('pid', '==', pack.id).get().then(res => {
                        if(!res.empty) {
                            res.docs.forEach((doc) => {
                                firebase.firestore().doc(`reviews/${doc.id}`).delete().catch(err => console.log(err))
                            })
                        }
                    }).catch(err => console.log(err))
                })
                history.goBack();
                setDeleteModal(null)
            })
		}
		catch(err) {
		    console.log(err)
		}
		finally {
		    setDeleting(false)
		}
	}

	const setDBCourses = async (id, payload) => {
		return firebase.firestore().doc(`packages/${id}`).update({ courses: payload})
	}



	const fetchSubscriberDetails = async (email) => {
		setSubscriberDetails("")
		setSubscriberDetailsError("")
		if(email) {
    		try {
                await firebase.firestore().collection("Users").where("email", "==", email).get().then((res) => {
                    const data = res.docs[0]
                    setSubscriberDetails(data.data().firstName ? data.data().firstName+" "+data.data().surname : data.data().name )
                    setSubscriberValue("userId", data.id)
                })
            }
            catch {
                setSubscriberDetailsError("User not found, please confirm email")
            }
		}
		else {
		    setSubscriberDetailsError("User Email is required")
		}
	}

	const addSubscriber = async () => {
        // date is paymentDate
		const  { email, amount, date, orderType, paymentRef, userId, deviceTypes } = newSubscriber
		if( userId && email && amount && date && orderType && orderType === `${deviceTypes.length}`) {
            setAddingSub(true)
            const addedDate = Date.now()
            const payload = {
                userId, email,
                paymentRef,
                amount,
                deviceTypes,
                orderType,
                date, addedDate
            }
            try {
                const res = pack.subscribers ? pack.subscribers.find((sub) => sub.userId === userId ) : false;
                if(!res) {
                    await firebase.firestore().doc(`packages/${pack.id}`)
                    .update({ subscribers: firebase.firestore.FieldValue.arrayUnion(payload) })
                    .then(async () => {
                        await firebase.firestore().collection(`orders`).add({
                        ...payload, status: 'active', package: pack.department + " " + pack.level + " " + pack.semester + " " + pack.type
                        });
                        alert("Successful");
                        resetSub();
                        init();
                    });
                } 
                else { alert("User has an active subscription to this package") }
            }
            catch(err) {
                console.log(err)
            }
            finally {
                setAddingSub(false)
            }
		}
		else {
            if(orderType !== `${deviceTypes.length}`) {
                alert(`Kindly select ${orderType} devices under device type`)
            } else {
    		    alert("Fields with * are required")
            }
		}
	}

	const resetSub = () => {
		setSubModal(false); setNewSubscriber(newSub); setSubscriberDetails(""); setSubscriberDetailsError("");
	}

    const getEmail = (id) => {
        const usr = utilities?.users?.find(({ uid }) => id === uid);
        return usr ? usr.email : id
    }


    /*
        NEW FUNCTIONS
    */
    const updatePackageDetails = async () => {
        const updated = Date.now()
        setProcessing('details')
        try {
            await firebase.firestore().doc(`packages/${pack.id}`).update({ ...packDetails, updated })
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setProcessing('')
        }
    }

    const updatePackageSubscribers = async (id) => {
        const subscribers = [ ...packSubscribers ].filter(({userId}) => userId !== id)
        const order = orders.find((order) => (order.package === pack.department + " " + pack.level + " " + pack.semester + " " + pack.type) && (order.userId === id))
        const updated = Date.now()
        try {
            await firebase.firestore().doc(`packages/${pack.id}`).update({ subscribers, updated })
            await firebase.firestore().doc(`orders/${order.orderId}`).update({ status: 'inactive', updated })
            init()
        }
        catch (err) {
            console.log(err)
        }
        
    }


    const toggleChips = (type) => {
        console.log(newSubscriber.orderType)
        if(newSubscriber.orderType !== '') {
            if(newSubscriber.deviceTypes.indexOf(type) > -1  && newSubscriber.orderType < '3') {
                var deviceTypes = [ ...newSubscriber.deviceTypes].filter((device) => device !== type )
                setNewSubscriber({ ...newSubscriber, deviceTypes })
            }
            else {
                if(newSubscriber.orderType > `${newSubscriber.deviceTypes.length}`) {
                    var deviceTypes = [ ...newSubscriber.deviceTypes]
                    deviceTypes.push(type)
                    setNewSubscriber({ ...newSubscriber, deviceTypes })
                }
            }
        }
    }

    const clearSubscribers = async () => {

        if(packSubscribers.length > 0) {
            setAlertProcessing(true)
            const subs = [ ...packSubscribers ];
            const updated = Date.now()
    
            await firebase.firestore().doc(`packages/${pack.id}`).update({ subscribers: [], updated }).then(async () => {
                const promise = new Promise((resolve, reject) => {
                    subs.forEach(async ({ userId }, index, array) => {
                        const order = orders.find((order) => (order.package === pack.department + " " + pack.level + " " + pack.semester + " " + pack.type) && (order.userId === userId))
                        await firebase.firestore().doc(`orders/${order.orderId}`).update({ status: 'inactive', updated })
                        .then(() => { if(subs.length == array.length) resolve() })
                        .catch(err => reject(err))
                    })
                });
    
                promise.then(async () => {
                    init()
                    setAlertActionModal(null)
                })
    
            }).catch((err) => console.log(err))
            .finally(() => {
                setAlertProcessing(false)
            });;
        }
    }

    /*
        useEffects
    */
    
	useEffect( () => {
		init()
	}, [])

	useEffect(() => {
		setPackages(general.packages)
		setUtilities(general.utilities)
		setOrders(general.orders)
	}, [general])

	useEffect(() => {
		if(packages.find(({id}) => id === pack.id)) {
            setPack(packages.find(({id}) => id === pack.id))
        }
	}, [packages])


    useEffect(() => {
        if(props.location.state?.pack){
            setPack(props.location.state?.pack)
        }
        if(props.location.state?.newPack){
            setNewPack(props.location.state?.newPack)
        }
    }, [props.location.state])

    useEffect( () => {
		setPackCourses(pack.courses)
		setPackSubscribers(pack.subscribers || [])
        getReviews()
	}, [pack])

    useEffect(() => {
        setUserData(user.userData)
    }, [user])

    return (
        <AppLayout>
            <Grid padding={4} paddingBottom={10}>

                <Typography variant="h4" marginBottom={1}>Package Details</Typography>
                <Card>
                    <Grid container padding={3}>
                        <Grid item md={4} xs={12} padding={2}>
                            <TextField  fullWidth select required variant="outlined" SelectProps={{ native: true }} InputLabelProps={{ shrink: true }}
                                label="Select Package Type" name="type" onChange={handleChange} value={packDetails.type}
                            >
                                <option value=""> Select a value </option>
                                {
                                    utilities.materialTypes && utilities.materialTypes.map((type) => (
                                    <option key={type} value={type} style={{ padding: 10}}>
                                        {type}
                                    </option>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid item md={4} xs={12} padding={2}>
                            <TextField fullWidth select required variant="outlined" SelectProps={{ native: true }} InputLabelProps={{ shrink: true }}
                                label="Select University" name="university" onChange={handleChange} value={packDetails.university}
                            >
                                <option value=""> Select a value </option>
                                {
                                    utilities.universities && utilities.universities.map((university) => (
                                    <option key={university} value={university}>
                                        {university}
                                    </option>
                                ))}
                            </TextField>
                        </Grid>
                        
                        <Grid item md={4} xs={12} padding={2}>
                            <TextField fullWidth select required variant="outlined" SelectProps={{ native: true }} InputLabelProps={{ shrink: true }}
                                label="Select Department" name="department" onChange={handleChange} value={packDetails.department}
                            >
                                <option value=""> Select a value </option>
                                {
                                    utilities.departments && utilities.departments.map((dept) => (
                                    <option key={dept} value={dept}>
                                        {dept}
                                    </option>
                                ))}
                            </TextField>
                        </Grid>
                        
                        <Grid item md={4} xs={12} padding={2}>
                            <TextField fullWidth select required variant="outlined" SelectProps={{ native: true }} InputLabelProps={{ shrink: true }}
                                label="Select Level" name="level" onChange={handleChange} value={packDetails.level}
                            >
                                <option value=""> Select a value </option>
                                {utilities.levels && utilities.levels.map((level) => (
                                <option key={level} value={level}>
                                    {level}
                                </option>
                                ))}
                            </TextField>
                        </Grid>
                        
                        <Grid item md={4} xs={12} padding={2}>
                            <TextField fullWidth select required variant="outlined" SelectProps={{ native: true }} InputLabelProps={{ shrink: true }}
                                label="Select Semester" name="semester" onChange={handleChange} value={packDetails.semester}
                            >
                                <option value=""> Select a value </option>
                                {utilities.semesters && utilities.semesters.map((semester) => (
                                <option key={semester} value={semester}>
                                    {semester}
                                </option>
                                ))}
                            </TextField>
                        </Grid>
                        
                        <Grid item md={4} xs={12} padding={2}>
                            <TextField
                                fullWidth
                                label="Price"
                                name="price"
                                onChange={handleChange}
                                type="number"
                                value={packDetails.price}
                                variant="outlined"
                            />
                        </Grid>
                        
                        <Grid item md={12} xs={12} padding={2}>
                            <TextField
                                fullWidth
                                rows={3}
                                multiline
                                label="Package Description"
                                name="description"
                                onChange={handleChange}
                                type="text"
                                value={packDetails.description}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item md={4} xs={12} paddingX={2}>
                            { 
                                !newPack && 
                                <Button disabled={processing === 'details'} variant="contained" className="btn" onClick={() => updatePackageDetails()}>
                                    { processing === 'details' ? 'Updating...' : 'Update Package Details' }
                                </Button>
                            }
                        </Grid>
                    </Grid>
                    
                </Card>

                
                <Typography variant="h4" marginTop={5} marginBottom={1}>Package Courses</Typography>
                <Card>
                    <Grid padding={3}>
                        { 
                            packCourses.map((course, key) => { return (
                                <Accordion defaultExpanded={false} key={key} style={{ marginBottom: 10 }}>
                                    <AccordionSummary expandIcon={<ExpandMore />}>
                                        <div>
                                            <Typography className={classes.heading}>{course.title ? course.title : <i>Untitled</i>}</Typography>
                                            <Typography className={classes.secondaryHeading}><i>PDFs: {course.materials.length} &amp; Audios: {course?.audios?.length || 0}</i></Typography>
                                        </div>
                                    </AccordionSummary>

                                    <AccordionDetails className={classes.details}>
                                        <Grid container paddingX={2}>
                                            <Grid item md={12} xs={12} paddingBottom={3} paddingX={2}>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    label="Course Title"
                                                    placeholder="Type in a course title (ex: Labour Law)"
                                                    name="feature"
                                                    onChange={(e) => setCourseTitle(key, e.target.value)}
                                                    type="text"
                                                    value={course.title}
                                                    variant="outlined"
                                                    error={checkCourseTitle(course.title, key)}
                                                    helperText={checkCourseTitle(course.title, key) && 'A course with same title already exits'}
                                                />
                                            </Grid>
                                        
                                            {/* Materials */}
                                            <Grid item md={6} xs={12} paddingBottom={3} paddingX={2}>
                                                <Typography>Materials</Typography>
                                                <div style={{ display: 'flex', alignItems: 'center', borderWidth: 3, borderColor: '#000' }}>
                                                <Button onClick={() => hiddenFileInput.current.click()} >{ !material ?
                                                    <> 
                                                    <Publish /> 
                                                    <Typography style={{ fontSize: 10 }}>Upload</Typography>
                                                    </>
                                                    : 
                                                    <PictureAsPdf style={{ color:"brown" }} /> 
                                                }</Button>
                                                <input accept=".pdf" type="file" style={{ display: 'none'}} ref={hiddenFileInput} onChange={(e) => handleUpload(e.target) } />
                                                <TextField 
                                                    type="text"
                                                    placeholder="Enter Material Title"
                                                    variant="standard"
                                                    value={materialTitle}
                                                    onChange={(e) => setMaterialTitle(e.target.value)}
                                                />
                                                <Button variant="contained" disabled={checkTitle(key)} onClick={() => addMaterial(key)}> Add</Button>
                                                </div>
                                                <List dense={true}>
                                                { course.materials && course.materials.map( (mat, index) => 
                                                    <ListItem key={index}>
                                                    <PictureAsPdf style={{ color:"brown", marginRight: 10 }} /> 
                                                    <ListItemText
                                                        primary={`${mat.title}`}
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <IconButton onClick={() => { edit ? deleteMaterial(key, index, mat, edit, course.title ) : deleteMaterial(key, index) }} edge="end" aria-label="delete">
                                                        <Delete />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                    </ListItem>
                                                )}
                                                </List>
                                            </Grid>

                                            {/* Audios */}
                                            <Grid item md={6} xs={12} paddingBottom={3} paddingX={2}>
                                                <Typography>Audios</Typography>
                                                <div style={{ display: 'flex', alignItems: 'center', borderWidth: 3, borderColor: '#000' }}>
                                                <Button onClick={() => hiddenAudioFileInput.current.click()} >{ !audio ?
                                                    <> 
                                                    <Publish /> 
                                                    <Typography style={{ fontSize: 10 }}>Upload</Typography>
                                                    </>
                                                    : 
                                                    <LibraryMusic style={{ color:"blue" }} /> 
                                                }</Button>
                                                <input accept=".mp3" type="file" hidden ref={hiddenAudioFileInput} onChange={(e) => handleAudioUpload(e.target) } />
                                                <TextField 
                                                    type="text"
                                                    placeholder="Enter Audio Title"
                                                    variant="standard"
                                                    value={audioTitle}
                                                    onChange={(e) => setAudioTitle(e.target.value)}
                                                />
                                                <Button variant="contained" disabled={checkTitle(key)} onClick={() => addAudioMaterial(key)}> Add</Button>
                                                </div>
                                                <List dense={true}>
                                                { course.audios && course.audios.map( (mat, index) => 
                                                    <ListItem key={index}>
                                                    <LibraryMusic style={{ color:"blue", marginRight: 10 }} /> 
                                                    <ListItemText
                                                        primary={`${mat.title}`}
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <IconButton onClick={() => { edit ? deleteAudio(key, index, mat, edit, course.title ) : deleteAudio(key, index) }} edge="end" aria-label="delete">
                                                        <Delete />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                    </ListItem>
                                                )}
                                                </List>
                                            </Grid>
                                            
                                            {/* Features  */}
                                            <Grid item md={6} xs={12} paddingBottom={3} paddingX={2}>
                                                <div  style={{ borderRadius: 10, borderWidth: 2, borderColor: '#000', borderBottomWidth: 1 }}>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    label="Add Features"
                                                    placeholder="Type in a feature and hit 'Enter'"
                                                    name="feature"
                                                    onKeyDown={(e) => { if (e.key == 'Enter') { addToFeatures(key, e.target.value); e.target.value = "" }}}
                                                    type="text"
                                                    variant="outlined"
                                                />
                                                </div>
                                                <List dense={true}>
                                                { course.features && course.features.map( (feature, index) => 
                                                    <ListItem key={index}>
                                                    <ListItemText
                                                        primary={`${index + 1}. ${feature}`}
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <IconButton onClick={() => deleteFeature(key, index)} edge="end" aria-label="delete">
                                                        <Delete />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                    </ListItem>
                                                )}
                                                </List>
                                            </Grid>
                                            
                                            {/* References  */}
                                            <Grid item md={6} xs={12} paddingBottom={3} paddingX={2}>
                                                <TextField
                                                    fullWidth
                                                    label="Reference"
                                                    name="reference"
                                                    placeholder="Type in a reference and hit 'Enter'"
                                                    onKeyDown={(e) => { if (e.key == 'Enter') { addToReferences(key, e.target.value); e.target.value = "" } }}
                                                    type="text"
                                                    variant="outlined"
                                                />
                                            <List dense={true}>
                                            { course.references && course.references.map( (reference, index) => 
                                                <ListItem key={index}>
                                                <ListItemText
                                                    primary={`${index + 1}. ${reference}`}
                                                />
                                                <ListItemSecondaryAction>
                                                    <IconButton onClick={() => deleteReference(key, index)} edge="end" aria-label="delete">
                                                    <Delete />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                                </ListItem>
                                            )}
                                            </List>
                                        </Grid> 
                                        </Grid>
                                    </AccordionDetails>
                                    <Divider />
                                    <AccordionActions>
                                        <IconButton onClick={() => deleteCourse(key)} edge="end" aria-label="delete">
                                        <Delete style={{ color: 'brown' }} />
                                        </IconButton>
                                    </AccordionActions>
                                </Accordion>
                            )}) 
                        }  
                        
                        <Grid item xs={12} paddingY={1} style={{ display: 'flex' }}>
                            <Button variant="contained" className="btn" onClick={() => setPackCourses([ ...packCourses, newCourse])}>
                                Add Course
                            </Button>
                            {
                                !newPack &&
                                <Button variant="contained" className="btn" onClick={() => uploadMaterials()}>
                                    { adding ?               
                                        <Box position="relative" display="inline-flex">
                                            <CircularProgress value={progress} variant="determinate" style={{ color: "#fff", width: '30px', height: '30px' }}   />
                                            <Box
                                                top={0}
                                                left={0}
                                                bottom={0}
                                                right={0}
                                                position="absolute"
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                            <Typography style={{ fontSize: 10 }} variant="caption" component="div" color="#FFF">{`${Math.round(
                                                progress
                                            )}%`}</Typography>
                                            </Box>
                                        </Box> 
                                        : 'Save Package Courses'
                                    }
                                </Button>
                            }
                        </Grid>         
                    </Grid>
                </Card>

                {
                    (!newPack && !userData?.contributor) &&
                    <>
                        <Grid container style={{ margin: '3rem 0 .3rem' }}>
                            <Grid item xs={9}>
                                <Typography variant="h4">Package Subscribers</Typography>
                            </Grid>
                            <Grid item xs={3} textAlign="right">
                                <Button onClick={() => setAlertActionModal('clear')}>Clear All</Button>
                            </Grid>
                        </Grid>
                        <Card>
                            <Divider />
                            <PerfectScrollbar>
                                <Box sx={{ minWidth: 800 }}  style={{ padding: '0 1rem' }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ width: '50px' }} >SN</TableCell>
                                                <TableCell>User Email</TableCell>
                                                <TableCell>Amount</TableCell>
                                                <TableCell>Devices</TableCell>
                                                <TableCell>Date Added</TableCell>
                                                <TableCell>Action</TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {packSubscribers.sort((a, b) => (a.date > b.date) ? 1 : -1).map((sub, key) => (
                                                <TableRow
                                                    hover
                                                    key={key}
                                                >
                                                    <TableCell>{key + 1}</TableCell>

                                                    <TableCell>{sub.email || getEmail(sub.userId)}</TableCell>

                                                    <TableCell>&#8358;{sub.amount}</TableCell>

                                                    <TableCell>{sub.deviceTypes ? capitalize(sub.deviceTypes.join(', ')) : sub.orderType}</TableCell>
                                                    
                                                    <TableCell>{getDate(sub.date)}</TableCell>

                                                    <TableCell>
                                                        <Delete onClick={() => updatePackageSubscribers(sub.userId)} className="delete-button" />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Box>
                            </PerfectScrollbar>
                            
                            <Grid item xs={12} paddingX={2} paddingY={3} style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button variant="contained" className="btn" onClick={() => setSubModal(true)}>
                                    Add Subscriber
                                </Button>
                            </Grid>
                        </Card>
                    </>
                }

                {
                    (!newPack && !userData?.contributor) &&
                    <>
                        <Grid container style={{ margin: '3rem 0 .3rem' }}>
                            <Grid marginBottom={1} item xs={9}>
                                <Typography variant="h4">Package Reviews</Typography>
                            </Grid>
                        </Grid>
                        <Card>
                            <Divider />
                            <PerfectScrollbar>
                                <Box sx={{ minWidth: 800 }}  style={{ padding: '0 1rem' }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ width: '50px' }} >SN</TableCell>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Message</TableCell>
                                                <TableCell>Rating</TableCell>
                                                <TableCell>Date Added</TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {reviews.sort((a, b) => (a.added > b.added) ? 1 : -1).map((review, key) => (
                                                <TableRow
                                                    hover
                                                    key={key}
                                                >
                                                    <TableCell>{key + 1}</TableCell>

                                                    <TableCell>{review.name}</TableCell>

                                                    <TableCell>{review.message}</TableCell>

                                                    <TableCell>{review.rating}</TableCell>
                                                    
                                                    <TableCell>{getDate(review.added)}</TableCell>

                                                    {/* <TableCell>
                                                        <Edit onClick={() => {}} className="delete-button" />
                                                    </TableCell> */}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Box>
                            </PerfectScrollbar>
                            
                            <Grid item xs={12} paddingX={2} paddingY={3} style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button variant="contained" className="btn" onClick={() => setReviewModal(true)}>
                                    Add Review
                                </Button>
                            </Grid>
                        </Card>
                    </>
                }

               { 
                    newPack && 
                    <Grid item xs={12} paddingX={2} paddingY={5}>
                        <Button variant="contained" fullWidth style={{ height: 50 }} onClick={() => addNewPack()}>
                            { adding ? <CircularProgress size={20} color="inherit" /> : 'Add Package' }
                        </Button>
                    </Grid>
                }

               { 
                    !newPack && 
                    <Grid container item justifyContent="flex-end" xs={12} paddingX={2} paddingY={5}>
                        <Button variant="contained" style={{ height: 50, backgroundColor: 'brown' }} onClick={() => setDeleteModal(true)}>
                            { deleting ? <CircularProgress size={20} color="inherit" /> : 'Delete Package' }
                        </Button>
                    </Grid>
                }
            </Grid>


            {/* 
            
                SUBSCRIPTION ADDITION
            
            */}
			<Dialog TransitionComponent={Transition} maxWidth='lg' open={subModal} scroll='paper' disableBackdropClick disableEscapeKeyDown aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
                <DialogTitle id="scroll-dialog-title">
                Add a subscriber (Fields with * are required) <br/>
                { pack && 
                    <Typography variant="body2" id="scroll-dialog-title" style={{ paddingTop: 0 }}>
                    {pack.department} {pack.level} {pack.semester} {pack.type}
                    </Typography>
                }
                </DialogTitle>

                <DialogContent dividers={true}>
                    <div className={clsx(classes.column, classes.helper)}>
                        <Grid item md={12} xs={12} paddingBottom={3}>
                        <TextField
                            fullWidth
                            required
                            label="User Email"
                            placeholder="Make sure email is correct"
                            name="email"
                            onChange={(e) => setSubscriberValue(e.target.name, e.target.value)}
                            onBlur={(e) => fetchSubscriberDetails(e.target.value)}
                            type="email"
                            value={newSubscriber.email}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                        />
                        { subscriberDetails && <Typography color="green">{subscriberDetails}</Typography> }
                        { subscriberDetailsError && <Typography color="brown">{subscriberDetailsError}</Typography>}
                        </Grid>
                        <Grid item md={12} xs={12} paddingBottom={3}>
                        <TextField
                            fullWidth
                            label="Amount Paid"
                            required
                            name="amount"
                            onChange={(e) => setSubscriberValue(e.target.name, e.target.value)}
                            type="number"
                            value={newSubscriber.amount}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                        />
                        </Grid>
                        <Grid item md={12} xs={12} paddingBottom={3}>
                        <TextField
                            fullWidth
                            label="Payment Reference ID"
                            name="paymentRef"
                            onChange={(e) => setSubscriberValue(e.target.name, e.target.value)}
                            type="text"
                            value={newSubscriber.paymentRef}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                        />
                        </Grid>
                        <Grid item md={12} xs={12} paddingBottom={3}>
                        <TextField fullWidth select required variant="outlined" SelectProps={{ native: true }} InputLabelProps={{ shrink: true }}
                            label="Select Order Type" name="orderType" 
                            onChange={(e) => setSubscriberValue(e.target.name, e.target.value)}
                            value={newSubscriber.orderType}
                        >
                            <option value=""> Select a value </option>
                            <option value="1">One Device (Android or IOS or Laptop)</option>
                            <option value="2">Two Devices (Android + IOS or Android + Laptop or IOS + Laptop)</option>
                            <option value="3">Three Devices (Android + IOS + Laptop)</option>
                        </TextField>
                        </Grid>

                        <InputLabel style={{ fontSize: '12px' }}>Select Device Type *</InputLabel>
                        <Grid item md={12} xs={12} paddingBottom={3} container>
                            <Grid item xs={4} padding={0.5}>
                                <Chip 
                                    label='Android'
                                    className={(newSubscriber.deviceTypes.indexOf('android') > -1) ? "chip active-chip" : 'chip'}
                                    onClick={() => toggleChips('android')}
                                />
                            </Grid>
                            <Grid item xs={4} padding={0.5}>
                                <Chip label='IOS' 
                                    className={(newSubscriber.deviceTypes.indexOf('ios') > -1) ? "chip active-chip" : 'chip'}
                                    onClick={() => toggleChips('ios')}
                                />
                            </Grid>
                            <Grid item xs={4} padding={0.5}>
                                <Chip label='Laptop' 
                                    className={(newSubscriber.deviceTypes.indexOf('laptop') > -1) ? "chip active-chip" : 'chip'}
                                    onClick={() => toggleChips('laptop')}
                                />
                            </Grid>
                            

                            
                        </Grid>
                        <Grid item md={12} xs={12} paddingBottom={3}>
                        <TextField fullWidth type="date" required variant="outlined" SelectProps={{ native: true }} InputLabelProps={{ shrink: true }}
                            label="Payment Date" name="date" onChange={(e) => setSubscriberValue(e.target.name, e.target.value)} value={newSubscriber.date}
                            InputLabelProps={{ shrink: true }}
                        >
                        </TextField>
                        </Grid>
                    </div>
                                
                </DialogContent>
                <DialogActions>
                <Button disabled={addingSub} onClick={() => resetSub()} color="primary">
                    Cancel
                </Button>
                <Button disabled={addingSub}  variant="contained" onClick={() => addSubscriber() }>
                    { addingSub ? 'Adding...' : 'Add'}
                </Button>
                </DialogActions>
            </Dialog>
		

			<Dialog TransitionComponent={Transition} maxWidth='lg' open={reviewModal} scroll='paper' disableBackdropClick disableEscapeKeyDown aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
                <DialogTitle id="scroll-dialog-title">
                    Add Review
                </DialogTitle>

                <DialogContent dividers={true}>
                    <div className={clsx(classes.column, classes.helper)}>
                        <Grid item md={12} xs={12} paddingBottom={3}>
                            <TextField
                                fullWidth
                                label="Name"
                                required
                                name="name"
                                onChange={(e) => setReviewForm({ ...reviewForm, name: e.target.value })}
                                value={reviewForm.name}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item md={12} xs={12} paddingBottom={3}>
                            <TextField
                                fullWidth
                                label="Rating" type="number"
                                required
                                name="rating" InputProps={{ inputProps: { min: 0, max: 5 } }}
                                onChange={(e) => setReviewForm({ ...reviewForm, rating: parseFloat(e.target.value) })}
                                value={reviewForm.rating}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item md={12} xs={12} paddingBottom={3}>
                            <TextField
                                fullWidth multiline rows={3}
                                label="Message"
                                name="message"
                                onChange={(e) => setReviewForm({ ...reviewForm, message: e.target.value })}
                                type="text"
                                value={reviewForm.message}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item md={12} xs={12} paddingBottom={3}>
                            { reviewError && <Typography className="error-message">{reviewError}</Typography> }
                        </Grid>
                    </div>
                                
                </DialogContent>

                <DialogActions>
                <Button disabled={addingReview} onClick={() => { setReviewModal(false); setReviewForm({ message: '', rating: 0, name: '' }) }} color="primary">
                    Cancel
                </Button>
                <Button disabled={addingReview}  variant="contained" onClick={() => addReview() }>
                    { addingReview ? 'Adding...' : 'Add'}
                </Button>
                </DialogActions>
            </Dialog>
		


            <Dialog TransitionComponent={Transition} maxWidth='lg' open={Boolean(alertAction)} scroll='paper' disableBackdropClick disableEscapeKeyDown aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
                <DialogTitle id="scroll-dialog-title">
                    {alertAction && alertAction === 'clear' ? 'Clear all subscriptions?' : alertAction === 'successful' ? 'Successful': 'Delete Package?'}
                </DialogTitle>
                <DialogContent dividers={true}>
                    { 
                        alertAction && alertAction === 'successful' ?
                        <Typography>Update was successful</Typography> :
                        <Typography>
                            Are you sure you want to 
                            {
                                alertAction === 'clear' ?
                                ' clear all subscriptions?'
                                : 
                                ' delete this package?'
                            }
                        </Typography>
                    }
                </DialogContent>
                <DialogActions>
                <Button disabled={alertProcessing} onClick={() => setAlertActionModal(null)} color="primary">
                    { alertAction && alertAction === 'successful' ? 'Close' : 'Cancel' }
                </Button>
                { alertAction && alertAction !== 'successful' && <Button disabled={alertProcessing}  variant="contained" onClick={() => (alertAction === 'clear' ) ? clearSubscribers() : deletePackage() } style={{  backgroundColor: alertAction === 'clear' ? '#7927d3' : 'red' }}>
                    { alertProcessing ? 'Processing...' : 'Confirm'}
                </Button>}
                </DialogActions>
            </Dialog>
                    
            
			<Dialog TransitionComponent={Transition} maxWidth='lg' open={deleteModal} scroll='paper' disableBackdropClick disableEscapeKeyDown aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
				<DialogTitle id="scroll-dialog-title">Confirm Delete
				</DialogTitle>
				<DialogContent dividers={true}>
					<Typography>Are you sure you want to delete this package?</Typography>
				</DialogContent>
				<DialogActions>
				<Button disabled={deleting} onClick={() => setDeleteModal(false)} color="primary">
					Cancel
				</Button>
				<Button disabled={deleting}  variant="contained" onClick={() => deletePackage() } style={{  backgroundColor: 'red' }}>
					{ deleting ? 'Deleting...' : 'Confirm'}
				</Button>
				</DialogActions>
			</Dialog>
        
        </AppLayout>
    )
}


const mapStateToProps = ({ general, user }) => ({ general, user });

const mapDispatchToProps = (dispatch) =>{
  return bindActionCreators({ fetchPackages, fetchUtilities }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPackage);