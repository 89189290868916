const Logo = ({ type, ...props }) => (
  <img
    width={50}
    style={{ marginLeft: 20}}
    alt="Logo"
    src={ type ? "/favicon.png" : "/logo.png"}
    {...props}
  />
);

export default Logo;
