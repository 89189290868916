import firebase from "./config";

export default {
  loginUser({email, password}) {
    return firebase.auth().signInWithEmailAndPassword(email, password)
  },
  getUser(email) {
    return firebase.firestore().collection('contributors').where('email', '==', email).get();
  },
  logoutUser() {
    return firebase.auth().signOut();
  },
  resetPassword(payload) {
    return firebase.auth().sendPasswordResetEmail(payload.email);
  },
};
