import { Helmet } from 'react-helmet';
import { Box, Container, Button, Grid, LinearProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import LatestOrders from 'src/components/dashboard//LatestOrders';
import LatestPackages from 'src/components/dashboard//LatestPackages';
import Dashlet from 'src/components/dashboard/Dashlet';
import { Article } from '@material-ui/icons';
import { blue, teal, purple, pink } from '@material-ui/core/colors';
import AppLayout from '../components/AppLayout';
import { fetchAll } from '../redux/actions/general'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const Orders = ({ fetchAll, general, user }) => {
    const [ utilities, setAnalytics ] = useState(general)
    const [ fetching, setFetching ] = useState(false)
    const [ userData, setUserData ] = useState(user.userData)
  
    const [ allActiveOrders, setAllActiveOrders ] = useState('')
    const [ activeAmount, setActiveAmount ] = useState('')
    const [ allAmount, setAllAmount ] = useState('')
    
    const fetchAllData = async () => {
        setFetching(true)
        try{
            await fetchAll(userData?.uid);
            
        }
        catch( err) {
    
        }
        finally{
            setFetching(false)
        }
    }
  
    const getData = () => {
        var active = 0, activeAmt = 0, allAmt = 0   
        utilities.orders.forEach(({ amount }) => allAmt += (amount*1));
        active = utilities.orders.filter((order) => order?.status !== "inactive")
        active.forEach(({ amount }) => activeAmt += (amount*1));

        setActiveAmount(activeAmt.toLocaleString())
        setAllActiveOrders(active.length)
        setAllAmount(allAmt.toLocaleString())
    }
  
    useEffect(() => {
        fetchAllData()
    }, [])
  
    useEffect(() => {
        setAnalytics(general)
    }, [general])

    useEffect(() => {
        getData()
    }, [utilities])

    useEffect(() => {
        setUserData(user.userData)
    }, [user])
  
    const lets = [
      { title: 'Active Orders', icon: <Article />, color: purple[600], data: allActiveOrders, growth: '' },
      { title: 'Active Order Amount', icon: <Article />, color: blue[600], data: `₦${activeAmount}`, growth: '' },
      { title: 'All Orders', icon: <Article />, color: pink[600], data: utilities && utilities.orders.length, growth: '' },
      { title: 'All Orders Amount', icon: <Article />, color: teal[600], data: `₦${allAmount}`, growth: '' },
    ]
  
    return (
      <AppLayout>
        <Helmet>
          <title>All Orders | GMT Dashboard</title>
        </Helmet>
        
        <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
          <Container maxWidth={false}>
              { fetching && <LinearProgress style={{ marginBottom: 20}} /> }
              <Grid container spacing={3}>
                  { lets.map(({title, icon, color, data, growth}, key) => {
                      return (
                      <Grid item lg={3} sm={6} xl={3} xs={12} key={key}>
                          <Dashlet title={title} color={color} icon={icon} data={data} growth={growth}  />
                      </Grid>
                      )
                  }) 
                  }
              </Grid>
            
              <Grid container spacing={3} style={{ marginTop: '.5rem' }}>
                  <Grid item xs={12}>
                      <LatestOrders orders={utilities.orders} />
                  </Grid>
              </Grid>
          </Container>
        </Box>
      </AppLayout>
    )
  };
  const mapStateToProps = ({ general, user }) => ({ general, user })
  
  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ fetchAll }, dispatch)
  }
  export default connect(mapStateToProps, mapDispatchToProps)(Orders)
