import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ShoppingBagOutlined, Input as InputIcon } from '@material-ui/icons'
import { Avatar, Box, Button, Divider, Drawer, Hidden, List, Typography } from '@material-ui/core';
import { logoutUser } from '../redux/actions/user'
import { Sliders as SlidersIcon, BarChart as BarChartIcon, Lock as LockIcon, Settings as SettingsIcon, ShoppingBag as ShoppingBagIcon,
  User as UserIcon, UserPlus as UserPlusIcon, Users as UsersIcon, Book as BookIcon } from 'react-feather';
import NavItem from './NavItem';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const DashboardSidebar = ({ onMobileClose, openMobile, logoutUser, user }) => {
    const [ userData, setUserData ] = useState(user.userData)
    const location = useLocation();
      
    const items = [
        {
            href: '/dashboard',
            icon: BarChartIcon,
            title: 'Dashboard'
        },
        {
            href: '/packages',
            icon: BookIcon,
            title: 'Packages'
        },
        {
            href: '/orders',
            icon: ShoppingBagIcon,
            title: 'Orders',
        },
        {
          href: '/contributors-list',
          icon: UsersIcon,
          title: 'Contributors',
          show: Boolean(!userData?.contributor)
        },
        {
            href: '/account',
            icon: UserIcon,
            title: 'Account',
            show: Boolean(userData?.contributor)
        },
        {
            href: '/utilities',
            icon: SlidersIcon,
            title: 'Utilities',
            show: Boolean(!userData?.contributor)
        },
        // {
        //   href: '/settings',
        //   icon: SettingsIcon,
        //   title: 'Settings'
        // },
        // {
        //   href: '/login',
        //   icon: LockIcon,
        //   title: 'Login'
        // },
        // {
        //   href: '/register',
        //   icon: UserPlusIcon,
        //   title: 'Register'
        // },
    ];

    useEffect(() => {
        if (openMobile && onMobileClose) {
        onMobileClose();
        }
    }, [location.pathname]);

    useEffect(() => {
        setUserData(user.userData)
    }, [user]);

    const getRole = () => {
        return userData.contributor ? 'Contributor' : 'Admin'
    }

    const content = (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
            }}
        >
            { 
                userData?.contributor && 
                <>
                    <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', p: 2}}>
                        <Avatar
                            component={RouterLink}
                            src={userData?.photoURL || '/static/images/avatars/avatar_6.png'}
                            sx={{
                                cursor: 'pointer',
                                width: 64,
                                height: 64
                            }}
                            to="/account"
                        />
                        <Typography color="textPrimary" variant="h5">
                            {userData.name}
                        </Typography>
            
                        <Typography color="textSecondary" variant="body2">
                            {getRole()}
                        </Typography>
                    </Box>
                    <Divider />
                </>
            }
            <Box sx={{ p: 2 }}>
                <List>
                    {items.map(({ show = true, ...item }) => (
                        show && 
                        <NavItem
                            href={item.href}
                            key={item.title}
                            title={item.title}
                            icon={item.icon}
                        />
                    ))}
                    <NavItem
                        onClick={async () => { await logoutUser() } }
                        href={"#"}
                        title="Logout"
                        icon={InputIcon}
                    />
                </List>
            </Box>
        </Box>
    );

    return (
        <>
            <Hidden lgUp>
                <Drawer
                anchor="right"
                onClose={onMobileClose}
                open={openMobile}
                variant="temporary"
                PaperProps={{
                    sx: {
                    width: 256
                    }
                }}
                >
                {content}
                </Drawer>
            </Hidden>
            <Hidden lgDown>
                <Drawer
                anchor="left"
                open
                variant="persistent"
                PaperProps={{
                    sx: {
                    width: 256,
                    top: 64,
                    height: 'calc(100% - 64px)'
                    }
                }}
                >
                {content}
                </Drawer>
            </Hidden>
        </>
    );
};

DashboardSidebar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
    onMobileClose: () => { },
    openMobile: false
};


const mapStateToProps = ({ user }) => ({ user })

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ logoutUser }, dispatch )
}  

export default connect(mapStateToProps, mapDispatchToProps)(DashboardSidebar);
