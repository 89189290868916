// User
export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_DATA = "USER_DATA";

export const SET_ALL = "SET_ALL";
export const SET_PACKAGES = "SET_PACKAGES";
export const SET_PROJECTS = "SET_PROJECTS";
export const SET_USERNOS = "SET_USERNOS";
export const SET_UTILITIES = "SET_UTILITIES";
export const SET_ORDERS = "SET_ORDERS";
export const SET_CONTRIBUTORS = "SET_CONTRIBUTORS";

export const ADD_PACKAGE = "ADD_PACKAGE";