import moment from 'moment';
import { v4 as uuid } from 'uuid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Button, Card, CardHeader, Chip, Divider, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Tooltip
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useHistory } from 'react-router-dom';

const LatestOrders = ({ orders, limit = orders.length, ...props}) => {
    const history = useHistory()
  const getDate = (value) => {
    return new Date(value).toLocaleString('en-GB').split(',')[0]
  }
  return (
    <Card {...props}>
      <CardHeader title={limit === orders.length ? 'All Orders' : `Latest Orders (${limit ? limit : 'All'})`} />
      <Divider />
      <PerfectScrollbar>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>S/N</TableCell>
                <TableCell>User Email</TableCell>
                <TableCell>Package</TableCell>
                <TableCell>Amount</TableCell>

                <TableCell sortDirection="desc">
                  <Tooltip
                    enterDelay={300}
                    title="Sort"
                  >
                    <TableSortLabel
                      active
                    //   direction="desc"
                    >
                      Date
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>

                {/* <TableCell>Status</TableCell> */}
              </TableRow>
            </TableHead>

            <TableBody>
              {orders.sort((a, b) => (a.date > b.date) ? -1 : 1).map((order, key) => (
                (key < limit) &&
                <TableRow
                    hover
                    key={key}
                >
                <TableCell>
                    {key + 1}
                </TableCell>
                <TableCell>
                    {order.email}
                </TableCell>
                <TableCell>
                    {order.package}
                </TableCell>
                <TableCell>
                    &#8358;
                    {order.amount}
                </TableCell>
                <TableCell>
                    {getDate(order.date)}
                </TableCell>
                {/* <TableCell>
                    <Chip
                    color="primary"
                    label={order.status}
                    size="small"
                    />
                </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        { limit < orders.length && 
            <Button
                color="primary"
                endIcon={<ArrowRightIcon />}
                size="small"
                variant="text"
                onClick={() => history.push('/orders')}
            >
            View all
            </Button>
        }
      </Box>
    </Card>
  );
}

export default LatestOrders;
