import { Helmet } from 'react-helmet';
import { Box, Container, Button, Grid, LinearProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import LatestOrders from 'src/components/dashboard//LatestOrders';
import LatestPackages from 'src/components/dashboard//LatestPackages';
import Dashlet from 'src/components/dashboard/Dashlet';
import { People, FolderShared, LibraryBooks, Article } from '@material-ui/icons';
import { red, green, orange, indigo, blue, teal, purple, pink } from '@material-ui/core/colors';
import AppLayout from '../components/AppLayout';
import { fetchAll } from '../redux/actions/general'
import { logoutUser } from '../redux/actions/user'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const Dashboard = ({ fetchAll, general, logoutUser, user }) => {
  const [ utilities, setAnalytics ] = useState(general)
  const [ userData, setUserData ] = useState(user.userData)
  const [ fetching, setFetching ] = useState(false)
  const [ allActiveOrders, setAllActiveOrders ] = useState('')
  const [ activeAmount, setActiveAmount ] = useState('')
  const [ allAmount, setAllAmount ] = useState('')
  
  const fetchAllData = async () => {
    setFetching(true)
    try{
        await fetchAll(userData?.uid);
        
    }
    catch( err) {
        if(err?.code === 'permission-denied') {
            await logoutUser()
        }
    }
    finally{
      setFetching(false)
    }
  }

    const getData = () => {
        var active = 0, activeAmt = 0, allAmt = 0   
        utilities.orders.forEach(({ amount }) => allAmt += (amount*1));
        active = utilities.orders.filter((order) => order?.status !== "inactive")
        active.forEach(({ amount }) => activeAmt += (amount*1));

        setActiveAmount(activeAmt.toLocaleString())
        setAllActiveOrders(active.length)
        setAllAmount(allAmt.toLocaleString())
    }

    useEffect(() => {
        fetchAllData()
    }, [])

    useEffect(() => {
        setAnalytics(general)
    }, [general])

    useEffect(() => {
        setUserData(user.userData)
    }, [user])

    useEffect(() => {
        getData()
    }, [utilities])

    const allDashlets = [
        { title: 'Packages', icon: <LibraryBooks />, color: red[600], data: utilities && utilities.packages.length, growth: '' },
        { title: 'Users', icon: <People />, color: green[600], data: utilities && utilities.userNos, growth: '' },
        { title: 'Contributors', icon: <FolderShared />, color: orange[600], data: `${utilities?.contributors?.length || 0}`, growth: '' },
        { title: 'Projects', icon: <Article />, color: indigo[600], data: utilities && utilities.projects.length, growth: '' },
        { title: 'Active Orders', icon: <Article />, color: purple[600], data: allActiveOrders, growth: '' },
        { title: 'Active Order Total', icon: <Article />, color: blue[600], data: `₦${activeAmount}`, growth: '' },
        { title: 'All Orders', icon: <Article />, color: pink[600], data: utilities && utilities.orders.length, growth: '' },
        { title: 'All Orders Total', icon: <Article />, color: teal[600], data: `₦${allAmount}`, growth: '' },
    ]
    const contributorDashlets = [
        { title: 'Packages', icon: <LibraryBooks />, color: red[600], data: utilities && utilities.packages.length, growth: '' },
        { title: 'Active Orders', icon: <Article />, color: purple[600], data: allActiveOrders, growth: '' },
        { title: 'Active Order Total', icon: <Article />, color: blue[600], data: `₦${activeAmount}`, growth: '' },
        { title: 'All Orders', icon: <Article />, color: pink[600], data: utilities && utilities.orders.length, growth: '' },
        { title: 'All Orders Total', icon: <Article />, color: teal[600], data: `₦${allAmount}`, growth: '' },
    ]


    const getDashlets = () => {
        return userData?.contributor ? contributorDashlets : allDashlets 
    }
    return (
        <AppLayout>
        <Helmet>
            <title>Dashboard | GMT Admin</title>
        </Helmet>
        
        <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
            <Container maxWidth={false}>
                { fetching && <LinearProgress style={{ marginBottom: 20}} /> }
                <Grid container spacing={3}>
                    { getDashlets().map(({title, icon, color, data, growth}, key) => {
                        return (
                        <Grid item lg={3} sm={6} xl={3} xs={12} key={key}>
                            <Dashlet title={title} color={color} icon={icon} data={data} growth={growth}  />
                        </Grid>
                        )
                    }) 
                    }
                </Grid>
            
                <Grid container spacing={3} style={{ marginTop: '.5rem' }}>
                    <Grid item lg={4} md={12} xl={3} xs={12}>
                        <LatestPackages sx={{ height: '100%' }} packs={utilities.packages} />
                    </Grid>

                    <Grid item lg={8} md={12} xl={9} xs={12}>
                        <LatestOrders orders={utilities.orders} limit={20} />
                    </Grid>
                </Grid>
            </Container>
        </Box>
        </AppLayout>
    )
};
const mapStateToProps = ({ general, user }) => ({ general, user })

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ fetchAll, logoutUser }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
