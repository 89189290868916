import { Helmet } from 'react-helmet';
import { Container, Tab, Tabs, Typography, Box, Card, CardContent, TextField, InputAdornment, SvgIcon } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import UniversityList from 'src/components/utilities/UniversityList';
import DepartmentList from 'src/components/utilities/DepartmentList';
import UserList from 'src/components/utilities/UserList';
import AppLayout from 'src/components/AppLayout';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { fetchUtilities } from '../redux/actions/general'
import { bindActionCreators } from 'redux';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <div role="tabpanel"  hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
        {value === index && (
          <Box p={3}>
            {children}
          </Box>
        )}
      </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      height: 224,
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
}));


const Utilities = ({ fetchUtilities ,general }) => {
    const [value, setValue] = useState(0);
    const [utilities, setUtilities] = useState(general.utilities);
    const classes = useStyles();

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    
  useEffect(() => {
    setUtilities(general.utilities)
  }, [general])

  useEffect(() => {
    fetchUtilities();
  }, [])

    return (
        <AppLayout>
            <Helmet>
                <title>Utilities | GMT Admin</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default',  minHeight: '100%', py: 3 }}>
                <Container maxWidth={false}>
                    <Box sx={{ mt: 3 }}>
                        <Card>
                            <CardContent>
                                <Typography style={{ marginLeft: 30, marginTop: 20, textTransform: 'uppercase' }} variant="h1">Utilities</Typography>
                            </CardContent>
                        </Card>
                    </Box>
                    <Box sx={{ pt: 3 }} className={classes.root}   style={{ height: '100%', backgroundColor: '#fff', }} >
                        <Tabs orientation="vertical" variant="scrollable" value={value} onChange={handleChange} 
                            aria-label="Vertical tabs example" className={classes.tabs}
                        >
                            <Tab label="Universities" />
                            <Tab label="Departments" />
                            <Tab label="Users" />
                        </Tabs>
                        <TabPanel value={value} index={0}  style={{ width: '85%' }} >
                            <UniversityList list={utilities.universities ? utilities.universities : []} />
                        </TabPanel>
                        <TabPanel value={value} index={1} style={{ width: '85%' }}>
                            <DepartmentList list={utilities.departments ? utilities.departments : []} />
                        </TabPanel>
                        <TabPanel value={value} index={2} style={{ width: '85%' }}>
                            <UserList list={utilities.users ? utilities.users : []} />
                        </TabPanel>
                    </Box>
                </Container>
            </Box>
        </AppLayout>
    )
};

const mapStateToProps = ({ general }) => ({ general })

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ fetchUtilities }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Utilities);
